@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.aucStatus{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 10px 0 15px;
}

.aucStatus p {
    margin-bottom: 0px;
}

p.lotStatus{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 8px;
    color: #00B191;
}