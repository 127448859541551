@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* info BLOCK */
.infoBlock {
    font-family: 'Segoe UI';
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}

.infoBlock div:last-child{
    margin-bottom:0;
}
.infoBlockTitle{
    display: flex;
    width: 100%;
}
.infoBlock p{
    margin: 0;
    font-size: 14px;
    font-style: normal;
    color: #171725;
}
.infoBlock p.desc{
    font-family: 'Roboto';
    font-size: 10px;
    color: #696974;
    font-style: normal;
    margin: 0;
}
.infoBlock h2,.infoBlock h3{
    width:70%;
}
/* bankItem */
.bankItem{
    display: flex;
    flex-flow: column;
    width: 100%;
    border-top: 1px solid #E2E2EA;
}
.bankItemTitle{
    display: flex;
    width: 100%;
    margin: 8px 0 14px;
    font-weight: 600;
    color: #696974;
}
.bankItemContent{
    display: flex;
    flex-flow: row wrap;
}
.attention {
    border:1px solid #FFD200;
}
.orgTitle,
.orgAdress{
    width:100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.orgContact,
.orgId{
    width:50%;
    margin-bottom: 10px;
    padding-right: 20px;
    box-sizing: border-box;
}
.orgContact1{
    width:100%;
    margin-bottom: 10px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}