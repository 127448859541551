@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.buttonLink{
    width: 300px;
    height: 40px;
    background: #139DD9;
    border-radius: 4px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    color: #FAFAFB;
    cursor: pointer;
    margin: auto;
    margin-top: 20px;
}



.lineButtonLink{
    width: 320px;
}