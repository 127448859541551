.buttonsTabAuctionPull{
    display: flex;
    width: 1200px;
    border-bottom: 1px solid #e5e5e5;
    height: 50px;
    position: relative;
    margin-bottom: 15px;
}

.buttonsTabAuctionPull_buttons{
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5e5e5;
    border-top: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
}

.activ{
    border-top: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #fff;
    background-color: #fff;
}