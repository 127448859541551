/* JUST INPUT */
.inPutWrap{
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}
.inPutInfo{
    width: 100%;
    display: flex;
    position:relative;
    margin: 0 0 15px;
}

input.inPut {
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #171725;
    padding: 0px 10px 0 5px;
    box-sizing: border-box;
}
input.inPut::placeholder{
    width: 100%;
    height: 40px;
}
.focus,
input.inPut:hover,
input.inPut:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
/* .inPut{
    color: #171725;
} */
.half{
    width:49%;
}
.center{
    align-items: center;
}
.label{
    position: absolute;
    left: 8px;
    top: -5px;
    font-size: 11px;
    line-height: 9px;
    padding: 0px 5px;
    color: #92929D;
    background-color: #FFFFFF;;
    z-index: 2;
}
.focus + .label,
.inPut:hover + .label,
.inPut:focus + .label {
    color:#00AFF0;
    transition: .2s;
}
.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: -14px 0 14px 10px;
    position: relative;
}
.error{
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin: -14px 0 14px 10px;
    position: relative;
    color: #FFA047;
}
.alert{
    border: 1px solid #FFA047;
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    /* border: 1px solid #CED6E0; */
    border-radius: 4px;
    position: relative;
    color: #171725;
    padding: 0px 10px 0 5px;
    box-sizing: border-box;
}
.alert + .label{
    color: #FFA047;
}


/********DISABLED*INPUT*********/
input.inPutHide {
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #92929D;
    padding: 0px 10px 0 5px;
    box-sizing: border-box;
}
input.inPutHide::placeholder{
    width: 100%;
    height: 40px;
}
input.inPutHide:hover,
input.inPutHide:focus {
    /* border: 1px solid #00AFF0; */
    transition: .2s;
}
.inPutHide{
    color: #92929D;
}