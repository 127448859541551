.slimmerBlock {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}

.slimmerBlock_title{
    display: flex;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    height: auto;
}
.slimmer{
    position: absolute;
    right: 24px;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #139DD9;
    cursor: pointer;
}
.slimmerBlockContent{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 30px 0 0;
}