.aukpoisk_second_elem{
    width: calc(110px - 20px);
    padding: 10px;
}
.aukpoisk_second_elem a{
    text-decoration: none;
}

.aukpoisk_second_elem svg, .aukpoisk_second_elem img{
    width: 80px;
    height: 80px;
    margin: auto;
}

.aukpoisk_second_elem_text{
    width: 80px;
    margin: auto;
    text-align: center;
    text-decoration: none !important;
}

.aukpoisk_second{
    width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}