.mobMenu_wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
}

.mobMenu_leftBlock{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    overflow: auto;
}

.mobMenu_rightBlock{
    position: absolute;
    top: 0;
    left: 200px;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
}