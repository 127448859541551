.popDownBlock{
    width: 100%;
    height: auto;
    /* display: flex; */
    box-sizing: border-box;
    border-radius: 4px;
    margin: 10px 0;
}
.popDownTitle{
    width: 100%;
    position: relative;
    height: auto;
    border-bottom:1px solid #E2E2EA;
    font-size: 14px;
    font-weight: 600;
    padding: 0 0 10px;
    cursor: pointer;
    display: flex;
    align-content: center;
    align-items: center;
}
.popDownTitle:hover .svgIcon{
    fill:#139DD9;
}
.popDownTitle p{
    font-weight: 600;
    display: flex;
}
.svgChevron{
    position:absolute;
    right: 5px;
    bottom:15px;
    display: flex;
}
.svgChevUP {
    position:absolute;
    right: 5px;
    bottom:15px;
    transform: rotate(180deg);
    display: flex;
}
.svgChevUP .svgIcon {
    fill:#139DD9;
}
.popDownContent{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;

}
.hidden{
    display: none;
}