/* ADD DOCs */
.instructionBlock {
    border: 1px solid #139DD9;
    border-radius: 4px;
    width:100%;
    padding:24px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    margin: 20px 0 0 0;
    font-size: 14px;
}
.addInfo {
    width: 32px;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
}
.addInfoIcon{
    width: 32px;
    height: 32px;
    background-color: #FFD200;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.addDocInsturuction {
    display: flex;
    position: relative;
    width: 100%;
    position: relative;
    height: auto;
    padding-left: 24px;
    flex-flow: column wrap;
}
.addDocInsturuction p {
    margin: 0;
    font-size: 14px;
}
li{
        font-size: 14px;
}
.addDocInsturuction h3{
    display: inline-flex;
    width: 200px;
    margin: 0 0 0 0;
    height: 32px;
}
.addDocInsturuction .aCollapse {
    display: inline-flex;
    position: absolute;
    right: 0;
    margin: 0 0 0 0;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #139DD9;
    cursor: pointer;
}
a.instrLink {
    text-decoration-line: underline !important;
    color: #139DD9;
}
.addDocInsturuction ul{
    padding: 0 0 0 10px;
    font-size: 14px;
    margin:0;
}
.addDocInsturuction ul li{
    margin-left: 10px;
    list-style-type: '- ';
}
.svgIcon{
    cursor: default;
}
