.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    padding: 40px;
    background-color: #fff;
    text-align: center;
    position: absolute;
    top: 20%;
    bottom: 20%;
    width: calc(60% - 80px);
}

.popup_closeX{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
    background-color: #eee;
}

.popup_main_doc{
    position: absolute;
    top: 35px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    overflow: auto;
}

.mainDocumentsBlock{}

.chekbox{
    display: flex;
}

.popup_message_button{
    color: #fff;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    margin-left: 10px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    border: 0px solid #000;
    width: 200px;
}

.popup_message_buttonOff{
    background-color: #eee;
    color: #000;
}

.documentsBlock{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.uploadDoc{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /*height: 100px;*/
}

.pseudoCheckboxOn{
    border: 1px solid #000;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 7px;
}
.pseudoCheckboxOff{
    border: 1px solid #000;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 7px;
    color: #fff;
    background-color: #3f51b5;
}

.formLine{
    /*display: flex;*/
    /*height: 50px;*/
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}

.input__file_button_text {
    line-height: 1;
    margin-top: 1px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 20px;
}

.addDocElem{
    /*min-height: 100px;*/
}

.input__wrapper{
    margin-top: 20px;
    height: 50px;
}

.blockButtonsTypeDoc{
    display: flex;
}

.activeButton{
    background-color: cornflowerblue;
    color: cornsilk;
}

.inputText{
    margin: 20px !important;
}

.validfilterSelect{
    width: 280px;
    line-height: 30px;
    height: 50px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 100;
}

.widthBlok{
    width: 100%;
}

.selectWidth{
    width: 240px;
}