/* JUST INPUT */
.inPutInfo{
    width: 100%;
    display: flex;
    position:relative;
    margin-bottom: 15px;
}
.inPutWrap{
    width: 100%;
    display: flex;
    position:relative;
}
.chBox{
    width: 100%;
    /* height: 34px; */
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    transition: .2s;
    padding: 0 0 0 8px;
}
.chBox:hover{
    background-color: #E2E2EA;
    transition: .2s;
}
.checkBox{
    width: 14px;
    height: 14px;
    border: 1px solid #B5B5BE;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
}
.checkBoxOn{
    width: 14px;
    height: 14px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.70251 2.7801C1.30947 2.39211 0.676317 2.39621 0.28833 2.78925C-0.0996578 3.1823 -0.095558 3.81545 0.297487 4.20344L2.83844 6.7117C3.25647 7.12434 3.93843 7.08954 4.31227 6.63648L8.43799 1.63648C8.78949 1.21049 8.7291 0.580209 8.30311 0.228708C7.87713 -0.122792 7.24685 -0.0624085 6.89535 0.363579L3.46543 4.52033L1.70251 2.7801Z' fill='white'/%3e%3c/svg%3e ");
    background-color: #0062FF;
    background-repeat: no-repeat;
    background-position: right 40% top 50%, 0 0;
    border: 1px solid #0062FF;
}
.chBoxLabel{
    display: flex;
    margin-left: 10px;
    font-size: 14px;
    width: 95%;
}
.noDisplay{
    display:none;
}
.half{
    width:49%;
}
.center{
    align-items: center;
}
p.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: 0px 0 -10px 10px;
    top: -32px;
    position: relative;
}