.blockBidLot{
    font-family: 'Segoe UI';
    width: 100%;
    height: auto;
    border: 1px solid #139DD9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.blockBidLot_title{
    display: flex;
    align-items: baseline;
    color: #000;
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    margin: 0 0 20px 0;
}
.messageBlock{
    padding: 15px 25px;
    text-align: center;
    border: 1px solid #ffd200;
    font: 400 13px/18px "robotolight",Arial,sans-serif;
    margin-top: 25px;
}
.messageBlockAuth{
    margin-bottom: 25px;
}
.centerText{
    width: 100%;
    margin: 20px 0;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bidLotCost{
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-flow: row wrap;
}

.bidLotCost_title{
    /*margin-left: 30px;*/
    margin-right: 60px;
    color: #6d6d6d;
    font-size: 18px;
}

.bidInputText{
    margin-left: 30px;
    width: 250px;
}

.bidLotCost_titleEdit{
    margin-left: 30px;
}

.bidLotFile_titleWrap{
    display: flex;
    justify-content: left;
}

.bidLotFile_title{
    margin-left: 30px;
    margin-right: 630px;
    text-align: left;
    font-weight: 500;
}
.bidLotFile_helpText{
    color: #6d6d6d;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
}

.bidLotCost_input{
    color: #6d6d6d;
    font-size: 12px;
}

.bidLotCost_input input{
    width: 400px;
    border: 1px solid #5d5d5d;
    font-size: 14px;
    padding: 3px;
}
.chekbox{
    padding-left: 15px;
    display: flex;
    width: 100%;
    margin: 20px 0;
}
.pseudoCheckboxOn{
    width: 16px;
    height: 16px;
    border: 1px solid #92929D;
    border-radius: 4px;
    margin-right: 15px;
    cursor: pointer;
}
.pseudoCheckboxOff{
    width: 16px;
    height: 16px;
    border: 1px solid #0062FF;
    border-radius: 4px;
    cursor: pointer;
    background-color:#0062FF;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='5' viewBox='1 -2 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.70251 2.7801C1.30947 2.39211 0.676317 2.39621 0.28833 2.78925C-0.0996578 3.1823 -0.095558 3.81545 0.297487 4.20344L2.83844 6.7117C3.25647 7.12434 3.93843 7.08954 4.31227 6.63648L8.43799 1.63648C8.78949 1.21049 8.7291 0.580209 8.30311 0.228708C7.87713 -0.122792 7.24685 -0.0624085 6.89535 0.363579L3.46543 4.52033L1.70251 2.7801Z' fill='white'/%3e%3c/svg%3e ");
    background-size: cover;
    margin-right: 15px;
}
.chekboxLabel{
    display: flex;
    font-size: 14px;
}

.inpMargin{
    margin-left: 52px;
}
.btnAddDoc{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #139DD9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 24px 0px;
    color: #139DD9;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 16px;
    cursor: pointer;
}
.btnAddDoc:hover{
    color: #fff;
    border: 1px solid #139DD9;
    background-color: #139DD9;
    transition: .2s;
}
.buttonBlock{
    width: 100%;
    /* height:300px; */
    display: flex;
    user-select: none;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}

.btnDelete{
    height: 40px;
    display: flex;
    border: 1px solid #FFA047;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 24px auto 0px 0;
    color: #FFA047;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 16px;
    cursor: pointer;
}
.btnDelete:hover{
    color: #fff;
    border: 1px solid #FFA047;
    background-color: #FFA047;
    transition: .2s;
}
.btnEdit{
    height: 40px;
    display: flex;
    border:1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 24px 0 0 0;
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 16px;
    cursor: pointer;
}
.btnEdit:hover{
    color: #696974;
    border: 1px solid #E2E2EA;
    background-color: #E2E2EA;
    transition: .2s;
}
.btnPublish{
    height: 40px;
    display: flex;
    border: 1px solid #139DD9;
    background-color: #139DD9;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 24px 0 0 10px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 16px;
    cursor: pointer;
    transition: .6s;
}
.btnPublish:hover{
    color: #139DD9;
    border: 1px solid #139DD9;
    background-color: #fff;
    transition: .2s;
}
.btnDisabled{
    height: 40px;
    display: flex;
    border:1px solid #E2E2EA;
    background-color: #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 24px 0 0 10px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 16px;
    cursor: default;
    transition: .2s;
}
a{
    /*color: #585858;*/
    text-decoration: underline;
    cursor: pointer;
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input__wrapper {
    width: 100%;
    position: relative;
    /*margin: 15px 0;
    text-align: center;*/
}
   
.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
   
.input__file_button_text {
    line-height: 1;
    padding: 20px;
    /*border: 1px solid #000;*/
    border-radius: 5px;
}
   
.input__file_button {
    width: 100%;
    max-width: 280px;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    margin-left: 10px;
    text-decoration: underline;
}

.addFileForm{
    /* text-align: center; */
}
.elemCheckbox{
    margin-left: 30px;
    text-align: left;
}
.selectType{
    /*margin-left: 86px;*/
    width: 408px;
    padding: 3px;
    margin-bottom: 30px;
}
.borderLineFile{
    width: 1140px;
    margin: auto;
    height: 1px;
    background-color: #5d5d5d;
    margin-top: 30px;
    margin-bottom: 30px;
}
/* .button{
    width: 400px;
    padding: 15px 25px;
    background-color: #ffd200;
    border: 1px solid #ffd200;
    font: 600 13px/18px "robotolight",Arial,sans-serif;
    text-transform: uppercase;
    text-align: left;
    cursor: pointer;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #000;
} */
/* .button_disabled{
    background-color: #c5c5c5;
    border: 1px solid #c5c5c5;
    cursor: default;
} */

.titlelAddDoc{
    text-align: left;
    margin-left: 30px;
    margin-top: 10px;
    font-weight: 600;
    text-transform: uppercase;
}

.listDocument{
    display: flex;
    /*justify-content: space-between;*/
    padding-left: 30px;
    padding-right: 30px;
}

.bidLotCost_title_notMargin{
    margin-right: 0px;
    text-align: left !important;
}

.deleteDoc{
    cursor: pointer;
    margin-right: 20px !important;
}



@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.offElem{
    background: linear-gradient(#eee,#fff);
    text-decoration: line-through;
}

.buttonOff{
    background-color: #ccc;
}

.helperButtonPublishText{
    font-size: 13px;
}

.sizeHashe{
    font-size: 9px;
}

.documentCount{
    width: 20px;
}

.documentText{
    width: 700px;
}

.documentType{
    width: 400px;
}


.h3Title{
    padding-left: 10px;
    text-align: left !important;
}

.lineWrapAwards{
    width: 100%;
    display: flex;
    color: #000 !important;
    text-align: left !important;
}

.lineWrapAwards_text{
    width: calc(70% - 40px);
    padding: 10px;
    padding-left: 30px;
    text-align: left !important;
}

.lineWrapAwards_date{
    width: calc(30% - 20px);
    padding: 10px;
    text-align: left !important;
}

.lineWrapAwards_title{
    width: 150px;
    font-weight: 600;
    text-align: left !important;
}

.lineWrapAwards_titleContract{
    width: 510px;
    font-weight: 600;
    text-align: left !important;
}

.lineWrapAwards_content{
    width: 660px;
    overflow: hidden;
    text-align: left !important;
}

.lineWrapAwards_contentDate{
    width: 150px;
    overflow: hidden;
    text-align: left !important;
}

.buttonsContract{
    margin-bottom: 10px !important;
    width: 250px;
    text-align: left !important;
}

.lineBids_userDocumentHis{
    text-decoration: line-through;
}

.HelpInfo{
    display: flex;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 15px 10px;
    background-color: #ffd200;
    border-radius: 4px;
    width: auto;
    padding: 4px 12px;
}