.wrapperInfo{
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
}
.infoIcon{
    height: 40px;
    width: 40px;
    /* height: inherit; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}
.infoIcon:hover .svgInfo{
    fill:#139DD9;
    transition: .2s;
}
.inputInfoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 440px;
    height:auto;
    min-width: 440px;
    box-sizing: border-box;
    top: 22px;
    right: 22px;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    background: #FFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.noDisplay{
    display:none;
}
.inputInfoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlockBtn {
    width: auto;
    height: 32px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #139DD9;
    color: #139DD9;
    cursor: pointer;
    transition: .2s;
}
.inputInfoBlockBtn:hover{
    color: #fff;
    background-color: #139DD9;
    transition: .2s;
}
.inputInfoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}