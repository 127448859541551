.wrapPage  {
    width: 1176px;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    margin: 30px auto 0;
    padding: 0px 30px 10px 10px;
    box-sizing: border-box;
}
.wrapContent {
    width: 1176px;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 30px auto 0;
    padding: 0px 30px 10px 10px;
    box-sizing: border-box;
}
.navBlock{
    display: flex;
    flex-direction: column;
    width:100%;
}
.navBlockList {
    padding:0px;
    margin:10px 0;
    font-size: 14px;
    color: #171725;
}
li.navBlockItem{
    display: inline-block;
    cursor: pointer;
}
ul.navBlockList > li:not(:last-child):after {
    content: ' / ';
    margin: 0 8px 0 8px;
}
ul.navBlockList li:last-child {
    color: #92929D;
}
.objTitleBlock{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-flow: row nowrap;
}
.objTitle{
    display: flex;
    font-size: 32px;
    font-weight: 600;
    width: 65%;
    position: relative;
}
.objTitleBtnBlock{
    display: flex;
}
.objNav {
    position: relative;
    height: 50px;
    margin-top: 32px;
    display:flex;
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    border-bottom: 1px solid #E2E2EA;
}
.objNavItem {
    font-weight: 600;
    font-size: 14px;
    min-width: 100px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
    text-align: center;
}
.activeNavItem {
    font-weight: 600;
    font-size: 14px;
    min-width: 100px;
    /* cursor: pointer; */
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
    color: #139DD9;
    text-align: center;
}
.activeNavItem::before{
    position: absolute;
    height: 5px;
    width: 100%;
    top: 45px;
    left: 0px;
    background-color: #139DD9;
    content: ' ';
    border-radius: 10px 10px 0 0;
}
/* BTNs */

.publishBtn{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: #139DD9;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    box-sizing: border-box;
}
.publishBtn:hover{
    background: #fff;
    border: 1px solid #139DD9;
    color: #139DD9;
    transition: .2s;
}
.copyBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 0 0 8px;
}
.copyBtn:hover{
    border: 1px solid #92929D;
    background-color: #92929D;
    transition: .2s;
}
.copyBtn:hover .copySvg{
    fill: #fff;
    transition: .2s;
}
.deleteBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #FFA047;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 0 0 8px;
}
.deleteBtn:hover{
    border: 1px solid #FFA047;
    background-color: #FFA047;
    transition: .2s;
}
.deleteBtn:hover .delSvg{
    fill: #fff;
    transition: .2s;
}

.infoBlock{
    border: 1px solid #139DD9;
    border-radius: 4px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    margin: 20px 0 0 0;
    font-size: 14px;
    font-weight: 600;
}

.imgHover{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition:.2s;
}

.zoomIcon{
    position: relative;
    display:flex;
    opacity: 0;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    background:#139DD9;
    border-radius:50%;
}

.iconAdd{
    width: 32px;
    height: 32px;
    background-color: #FFD200;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.off{
    color: #92929D;
}