.bankBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #BFBFC6;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 0 10px;
}
.bankBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}
.arrestLine{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.arrestLineElem{
    width: calc(100% - 32px);
    padding: 8px 16px;
}
.checkbox{
    font-size: 12px;
}