.wrap{
    width: 100%;
}

.titleText{
    font: 400 16px/16px 'robotobold',Arial,sans-serif;
    text-transform: uppercase;
    line-height: 66px;
}

/*@media (min-width: 320px){
    .header_title{
        width: 300px;
        margin: auto;
    }
}

@media (min-width: 730px){
    .header_title{
        width: 710px;
        margin: auto;
    }
}

@media (min-width: 960px){
    .header_title{
        width: 940px;
        margin: auto;
    }
}

@media (min-width: 1280px){*/
    .header_title{
        /*width: 1260px;*/
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
/*}

@media (min-width: 1440px){
    .header_title{
        width: 1320px;
        margin: auto;
    }
}

@media (min-width: 1600px){
    .header_title{
        width: 1480px;
        margin: auto;
    }
}

@media (min-width: 1920px){
    .header_title{
        width: 1800px;
        margin: auto;
    }
}*/