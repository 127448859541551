.ecpWrap{
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    height:auto;
    border: 1px dashed #E2E2EA;
    /* background-color: #E2E2EA; */
    padding: 8px 10px 0;
    border-radius: 4px;
}
.ecpTitle{
    display: flex;
    width: 100%;
    height: 28px;
    flex-flow: row nowrap;
    font-weight: 600;
    justify-content: space-between;
    margin-bottom: 12px;
}
.keyBlock{
    display: flex;
    width: 24px;
    height: 24px;
    margin: 0 15px 0 0;
}
.docSigned{
    display: flex;
    margin-right: auto;
}
.ecpContent{
    display: flex;
    flex-flow: row wrap;
}
.btnDwnld{
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #E2E2EA;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s;
}
.btnDwnld:hover{
    border: 1px solid #00B191;
    background-color: #00B191;
    transition: .2s;
}
.btnDwnld:hover .dnldSvg{
    fill:#fff;
    transition: .2s;
}