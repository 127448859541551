@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.auctionTitle ul {
    padding:0px;
    margin:10px 0;
    font-size: 14px;
    color: #171725;
}
.auctionTitle ul li{
    display: inline-block;
    cursor: pointer;
    padding-left: 5px;
}
.auctionTitle ul li:last-child {
    color: #92929D;
}

.auctionTitle ul li:first-child {
    color: #171725;
}

.auctionTitle ul li:first-child:after {
    content: ' / ';
}

.auctionTitle ul > li:not(:last-child):after {
        content: ' / ';
}
.url{
    text-decoration: none;
    color: #00aff0;
}
h1.aucTitle{
    word-break: break-word;
}