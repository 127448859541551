/* SELECT */
.inPutWrap{
    width: 100%;
    display: flex;
    position:relative;
    margin: 0 0 15px;
}
.inPutSelect {
    width: 100%;
    min-height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color:inherit;
    outline:none;
    font-size: 14px;
    color: #92929D;
    padding: 10px 20px 10px 10px;
    position: relative;
    cursor: pointer;
    transition: .5s;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.inActive {
    width: 100%;
    min-height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color:inherit;
    outline:none;
    font-size: 14px;
    color: #92929D;
    padding: 10px 20px 10px 10px;
    position: relative;
    cursor: default;
    transition: .5s;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.textValue{
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.inPutSelect:after,
.inActive:after{
    position: absolute;
    border: 6px solid transparent; 
    border-top: 6px solid #92929D;
    top:18px;
    right: 8px;
    font-size: 16px;
    content: ' ';
    color: #92929D;
}
.focus.inPutSelect:after{
    top:12px;
    border: 6px solid transparent; 
    border-bottom: 6px solid #00AFF0;
}
.half{
    width:49%;
}
.center{
        align-items: center;
}
.focus,
.inPutSelect:hover,
.inPutSelect:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.label {
    position: absolute;
    left: 8px;
    top: -5px;
    font-size: 11px;
    line-height: 9px;
    padding: 0px 5px;
    color: #92929D;
    background-color: #FFFFFF;
    z-index: 2;
}
.focus + .label,
.inPutSelect:hover + .label,
.inPutSelect:focus + .label {
    color:#00AFF0;
    transition: .2s;
}
p.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: 0 0 5px 10px;
    top: -12px;
    position: relative;
}
.inPutInfo.attent .inPutSelect{
    border: 1px solid #FFA047;
}
.inPutInfo.attent + p,
.inPutInfo.attent .label{
    color: #FFA047;
}
.complete{
    color: #171725;
}
.infoIcon{
    height: inherit;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    cursor: pointer;
    position: relative;
}
.infoIcon:hover .svgInfo{
    fill:#139DD9;
    transition: .2s;
}
.inputInfoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 440px;
    height:auto;
    min-width: 440px;
    box-sizing: border-box;
    top: 22px;
    right: 22px;
    background: #FAFAFB;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    background: #FAFAFB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.noDisplay{
    display:none;
}
.inputInfoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlockBtn {
    width: auto;
    height: 32px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #139DD9;
    color: #139DD9;
    cursor: pointer;
}
.inputInfoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}

/* SELECT POPDOWN  */

.wrapperInfo,
.wrapper{
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
}
.selectPopdown{
    position:absolute;
    top: 45px;
    left: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 98%;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 12px 5px 8px;
    box-sizing: border-box;
    z-index: 9998;
    cursor:initial;
}
.somePart{
    padding: 2px 20px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    box-sizing: border-box;
}
.active{
    background-color: #FAFAFB;
    font-weight: 500;
}
.disabled{
    padding: 4px 20px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
    color: #BFBFC6;
}
.sectionWrap{
    position: relative;
    /* margin-bottom: 2px; */
    padding: 5px 0;
    overflow-y: auto;
}
.sectionWrap:last-child{
     margin-bottom: 0;
}
.mainDocs{
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 4px;
    border: 1px solid #FFA047;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    margin-top: 4px;
}
.mainDocsTitle{
    position: absolute;
    top: 0px;
    left: 20px;
    z-index: 99;
    color: #FFA047;
    background-color: #FFFFFF;
    padding: 0 5px;
}
.noMainDocs{
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 4px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

}
.noMainDocsTitle{
    position: absolute;
    top: -4px;
    left: 20px;
    z-index: 99;
    background-color: #FFFFFF;
    color: #92929D;
    padding: 0 5px;
}
.somePart:last-child {
    margin-bottom: 0;
}
.somePart:hover{
    background-color: #FAFAFB;
    font-weight: 500;
    /* transition: .2s; */
}
.somePart span{
    color: #92929D;
}