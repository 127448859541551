/* DATE */
.inPutWrap{
    width: 100%;
    display: flex;
    position:relative;
    margin: 0 0 15px ;
    flex-flow: column nowrap;
}

.inPutDate {
    width: 100%;
    height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color:inherit;
    outline:none;
    /* color: #92929D; */
    color: #171725;
    display: flex;
    padding: 0px 10px 0 0px;
    position: relative;
    cursor: pointer;
    transition: .2s;
}
.disabled{
    width: 100%;
    height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color:inherit;
    outline:none;
    color: #92929D;
    display: flex;
    padding: 0px 10px 0 0px;
    position: relative;
    cursor: pointer;
    transition: .2s;
}
.inPutAlert{
    width: 100%;
    height: 40px;
    border: 1px solid #FFA047;
    box-sizing: border-box;
    border-radius: 4px;
    background-color:inherit;
    outline:none;
    color: #92929D;
    display: flex;
    padding: 0px 10px 0 0px;
    position: relative;
    cursor: pointer;
    transition: .2s;
}
.beforeInput{
    display: flex;
    flex-shrink: 0;
    width: 35px;
    height: 100%;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M11.3337 2.74919V2.08244C11.3337 1.71418 11.6321 1.41565 12.0003 1.41565C12.3685 1.41565 12.667 1.71418 12.667 2.08244V2.74919H13.3337C14.4382 2.74919 15.3337 3.64478 15.3337 4.74955V6.74992V8.0835V11.4174C15.3337 13.627 13.5428 15.4182 11.3337 15.4182H4.66699C2.45785 15.4182 0.666992 13.627 0.666992 11.4174V8.0835V6.74992V4.74955C0.666992 3.64478 1.56242 2.74919 2.66699 2.74919H4.00033V2.08244C4.00033 1.71418 4.29881 1.41565 4.667 1.41565C5.03519 1.41565 5.33366 1.71418 5.33366 2.08244V2.74919H11.3337ZM2.00033 8.0835V11.4174C2.00033 12.8904 3.19424 14.0846 4.667 14.0846H11.3337C12.8064 14.0846 14.0003 12.8904 14.0003 11.4174V8.0835H2.00033ZM2.667 4.08272H4.00033V4.0828C4.00033 4.45106 4.29881 4.74959 4.667 4.74959C5.03519 4.74959 5.33366 4.45106 5.33366 4.0828V4.08272H11.3337V4.0828C11.3337 4.45106 11.6321 4.74959 12.0003 4.74959C12.3685 4.74959 12.667 4.45106 12.667 4.0828V4.08272H13.3337C13.7019 4.08272 14.0003 4.38126 14.0003 4.74951V6.74988H2.00033V4.74951C2.00033 4.38126 2.29881 4.08272 2.667 4.08272Z' fill='%2392929D'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    background-position: right 50% top 55%, 0 0;
}
.afterInput{
    display: flex;
    width: 100%;
    flex-shrink: 3;
    height: 100%;
}
.afterInput:after{
    position: absolute;
    border: 6px solid transparent; 
    border-top: 6px solid #92929D;
    top:18px;
    right: 8px;
    font-size: 16px;
    content: ' ';
    color: #92929D;
}
.hiddenInput{
    border: none;
    min-width: 140px;
    width: auto;
    display: flex;
    align-items: center;
    flex-shrink: 1;
    outline: none;
}
.hiddenInput:hover, 
.hiddenInput:active, 
.hiddenInput:focus{
    border: none;
    outline: none;
}
/* .hiddenInput:active .inPutDate, 
.hiddenInput:focus .inPutDate{
    border: 1px solid #00AFF0;
    transition: .2s;
} */
.focus.inPutDate:after{
    top:12px;
    border: 6px solid transparent; 
    border-bottom: 6px solid #00AFF0;
}
.half{
    width:49%;
}
.center{
        align-items: center;
}
.focus,
.inPutDate:hover,
.inPutDate:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.afterInput{
    display: flex;
    font-size: 14px;
    color: #FFA047;
    font-weight: 600;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px 0 0;
}
.label {
    position: absolute;
    left: 8px;
    top: -5px;
    font-size: 11px;
    line-height: 9px;
    padding: 0px 5px;
    color: #92929D;
    background-color: #FFFFFF;;
    z-index: 2;
}
.focus + .label,
.inPutDate:hover + .label,
.inPutDate:focus + .label {
    color:#00AFF0;
    transition: .2s;
}
.inPutAlert + .label{
    color:#FFA047;
    transition: .2s;
}
.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: 0px 0 -10px 10px;
    /* top: -32px; */
    position: relative;
}

p.descAlert{
    font-size: 12px;
    line-height: 16px;
    color: #FFA047;
    margin: 0px 0 -10px 10px;
    top: -32px;
    position: relative;
}
/* .complete{
    color: #171725;
} */
.infoIcon{
    height: inherit;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    cursor: pointer;
    position: relative;
}
.infoIcon:hover .svgInfo{
    fill:#139DD9;
    transition: .2s;
}
.inputInfoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 440px;
    height:auto;
    min-width: 440px;
    box-sizing: border-box;
    top: 22px;
    right: 22px;
    background: #FAFAFB;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    background: #FAFAFB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.noDisplay{
    display:none;
}
.inputInfoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlockBtn {
    width: auto;
    height: 32px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #139DD9;
    color: #139DD9;
    cursor: pointer;
}
.inputInfoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}

/* DATE POPDOWN  */

.wrapperInfo,
.wrapper{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.2);
    justify-content: center;
}
.datePopdown{
    position:absolute;
    margin: 15vh auto;
    /* top: 0px;
    left: 0px; */
    display: flex;
    max-width: 256px;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 12px;
    box-sizing: border-box;
    z-index: 9999;
    cursor:initial;
    color: #696974;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.dateTitle{
    height: auto;
    display: flex;
    /* justify-content: center; */
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    margin:0 auto 10px;
    color: #696974;
}
.topControls{
    width: 100%;
    min-height: 30px;
    height: auto;
    background: #00AFF0, 100%;
    border-radius: 10px;
    margin: 0 0 10px;
    display: flex;
    justify-content: space-around;
    font-size: 14px;
}
.caleendarWrap{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
}
.fieldMonth{
    width: 120px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    cursor:default;
    text-transform: uppercase;
}
.fieldYear{
    width: 80px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    cursor:default;
}
.backWd{
    width: 20px;
    height: 30px;
    border-radius: 10px 0 0 10px;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.forWd{
    width: 20px;
    height: 30px;
    border-radius: 0 10px 10px 0;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bwd{
    /* position: absolute; */
    border: 6px solid transparent; 
    border-right: 6px solid #92929D;
    top:18px;
    right: 8px;
    font-size: 16px;
    color: #92929D;
}
.fwd{
    border: 6px solid transparent; 
    border-left: 6px solid #92929D;
    top:18px;
    right: 8px;
    font-size: 16px;
    color: #92929D;
}
.backWd:hover .bwd{
    transition: .2s;
    border: 6px solid transparent; 
    border-right: 6px solid #00AFF0;
}
.forWd:hover .fwd{
    transition: .2s;
    border: 6px solid transparent; 
    border-left: 6px solid #00AFF0;
}
.fieldYear:hover,
.fieldMonth:hover,
.fieldYear:focus,
.fieldMonth:focus{
    outline: none;
    border: 1px solid #00AFF0;
}
.bottomPart{
    position:relative;
    width: 100%;
    height: auto;
    border-top: 1px solid #E2E2EA;
    padding: 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.bottomPart.btn{
    margin: 10px 0;
}
.daysTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:default;
}
.days{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfdf;
    cursor: pointer;
    border-radius: 8px;
}
.days:hover:not(.inactive){
    background-color: #FAFAFB;
    border: 1px solid #00AFF0;
    /* font-size: 16px; */
    /* font-weight: 600; */
}
.days:active:not(.inactive){
    background-color: #FAFAFB;
    border: 1px solid #00AFF0;
    font-weight: 600;
    color: #00AFF0;
    font-size: 16px;
}
.daysCurent:not(.inactive){
    /* background-color: #FAFAFB; */
    border: 1px solid #00AFF0;
    font-weight: 600;
    color: #00AFF0;
    font-size: 16px;
}
.daysToday:not(.inactive){
    background-color: #e9e9e9;
    /* font-weight: 600; */
    /* color: #00AFF0; */
    font-size: 14px;
}
.inactive{
    border:none;
    cursor: initial;
}
.calendarBlockVerySmall{
    display:grid;
    grid: repeat(4, 32px) / repeat(7, 32px);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    padding: 0 0 10px 0;
}
.calendarBlockSmall{
    display:grid;
    grid: repeat(5, 32px) / repeat(7, 32px);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    padding: 0 0 10px 0;
}
.calendarBlock{
    display:grid;
    grid: repeat(6, 32px) / repeat(7, 32px);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    padding: 0 0 10px 0;
}
.titleCalendar{
    margin: 0 0 10px 0;
    border-bottom: 1px solid #E2E2EA;
    padding: 0 0 5px 0;
    display:grid;
    grid: repeat(1, 32px) / repeat(7, 32px);
    grid-column-gap: 2px;
    grid-row-gap: 2px;
}
.blueBtn{
    width: auto;
    height: 30px;
    padding: 0 12px;
    cursor:pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    border: 1px solid #139DD9;
    color: #139DD9;
}
.blueBtn:hover {
    transition: .2s;
    background-color: #139DD9;
    color: #ffffff;
}
.greyBtn{
    width: auto;
    height: 30px;
    padding: 0 12px;
    cursor:pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    border: 1px solid #dfdfdf;
    color: #696974;
}
.greyBtn:hover {
    transition: .2s;
    background-color: #dfdfdf;
    /* color: #ffffff; */
}
.timePicker{
    position:relative;
    width: 100%;
    height: auto;
    border-top: 1px solid #E2E2EA;
    padding: 8px 0 ;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.timePicker::before{
    content:' ';
    position:absolute;
}
input{
    font-family: 'Segoe UI';
    font-size: 14px;
}
input[type=time]{
    width: 140px;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    cursor:default;
    padding: 0 0 0 8px;
    margin: 0 10px 0 10px;
    font-size: 14px;
    color: #92929D;
    text-align: center;
}
input[type=time]:focus{
    outline:none;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(0.78);
}
.preview{
    margin: 5px 0;
}