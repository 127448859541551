.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
}
.popup_message{
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 40px;
    background-color: #fff;
    text-align: center;
    max-width: 460px;
    border-radius: 4px;
    position: relative;
}
.popUpExit{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.popUpExit:hover .svgIcon{
    fill: #139DD9;
    transition: .2s;

}
.popup_btnBlock{
    width: 70%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    margin: 20px 0 0;
}
.popup_yes_button{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 10px 16px;
    width: 80px;
    height: 40px;
    border: 1px solid #139DD9;
    background-color: #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #FFF;
    cursor: pointer;
    margin: 0 0 0 12px;
    text-align: center;
    transition: .2s;
}
.popup_yes_button:hover{
    border: 1px solid #139DD9;
    background-color: #fff;
    color:#139DD9;
    transition: .2s;
}
.popup_no_button{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 10px 16px;
    width: 80px;
    height: 40px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    color: #696974;
    cursor: pointer;
    margin: 0 0 0 12px;
    transition: .2s;
}
.popup_no_button:hover{
    border: 1px solid #E2E2EA;
    background-color: #E2E2EA;
    color:#696974;
    transition: .2s;
}
.popUpTitle{
    display: flex;
    font-weight: 600;
    /* text-transform: uppercase; */
    font-size: 18px;
    margin: 20px auto 15px;
    text-align: center;
    color: #171725;
}
.inputWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    color: #6d6d6d;
}
.inputWrap div{
    margin-right: 30px;
}
.inputWrap input{
    width: 300px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
}