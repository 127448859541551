.buttonWrapper {
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}
.buttonCancel {
    padding: 11px 40px;
    border: 1px solid #027EFC;
    border-radius: 42px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #027EFC;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.buttonCancel:hover {
    border: double 1px transparent;
    background-image: linear-gradient(white, white), radial-gradient(circle at bottom right, #FFEC00, #027EFC);
    background-origin: border-box;
    background-clip: padding-box, border-box;
}
.buttonCancelGrey {
    background-color: #f6f6f6;
}
.buttonCancelGrey:hover {
    background-image: linear-gradient(#f6f6f6, #f6f6f6), radial-gradient(circle at bottom right, #FFEC00, #027EFC);
}

.buttonAccept {
    text-decoration: none;
    position: relative;
    padding: 12px 40px;
    border-radius: 42px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #027EFC;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.buttonAccept:hover {
    background: linear-gradient(329.85deg, #FFEC00 6.75%, #027EFC 91.35%);
}
.buttonReset {
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #FF3C4C;
    cursor: pointer;
    margin-right: auto;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 640px) {
    .buttonWrapper {
        flex-wrap: wrap;
    }
    .buttonCancel, .buttonAccept, .buttonCancel:hover, .buttonAccept:hover  {
        width: 100%;
        padding: 12px 40px;
    }
}