.aukcionmenu{
    width: 100%;
    background: #646c7a;
}

.aukcontent{
    display: flex;
    margin: auto;
    color: #fff;
}

.auktionmenublok{
    width: 100px;
    background: #FFF;
    border-top: 5px solid #00aff0;
    color: #646c7a;
    padding-left: 15px;
    padding-right: 15px;
    /*padding-top: 7px;*/
    padding-top: 20px;
    border-right: 1px solid #646c7a;
    /*text-align: center;*/
    text-transform: uppercase;
    font-size: 11px;
    /*margin-top: 10px;*/
    /*padding-bottom: 14px;*/
    padding-bottom: 2px;
    font-weight: 600;
    /*height: auto;*/
    height: 51px;
    margin-top: -5px;
    margin-left: -2px;
}

.auktionmenublok_off_inner{
    width: 100px;
    color: #d1d3d7;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    border-right: 1px solid #d1d3d7 !important;
    /*text-align: center;*/
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 10px;
    padding-bottom: 29px;
    font-weight: 600;
}

.auktionmenublok_off{
    width: 100px;
    color: #d1d3d7;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    border-right: 1px solid #d1d3d7 !important;
    /*text-align: center;*/
    text-transform: uppercase;
    font-size: 11px;
    margin-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
}

.auktionmenublok, .auktionmenublok_off{
    cursor: pointer;
}

.auktionmenublok_off:hover{
    color: #fff;
}

.auktionmenublok_off a{
    color: #d1d3d7;
    text-decoration: none;
}

.auktionmenublok_off:hover a{
    color: #fff;
}

.auktionmenublok_off:last-child{
    border-right: 0px solid #646c7a;
}

/*@media (min-width: 320px){
    .aukcontent{
        width: 300px;
    }
}

@media (min-width: 730px){
    .aukcontent{
        width: 710px;
    }
}

@media (min-width: 960px){
    .aukcontent{
        width: 940px;
    }
}

@media (min-width: 1280px){*/
    .aukcontent{
        /*width: 1260px;*/
        width: calc(1200px - 2px);
        padding-top: 5px;
        font-family:  'robotobold',Arial,sans-serif;
        padding-left: 2px;
    }
/*}

@media (min-width: 1440px){
    .aukcontent{
        width: 1320px;
    }
}

@media (min-width: 1600px){
    .aukcontent{
        width: 1480px;
    }
}

@media (min-width: 1920px){
    .aukcontent{
        width: 1800px;
    }
}*/



.categoryWrapMain{
    background-color: #fafafb;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
}
.categoryWrap{
    align-content: center;
    background-color: #fff;
    flex-flow: row wrap;
    justify-content: space-between;
    /*padding: 0 12px;*/
    width: 1202px;
    
    box-sizing: border-box;
    display: flex;
    /*height: 44px;*/
    margin: 0 auto;

    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}
.categoryWrap a{
    text-decoration: none;
}
.activeCategory{
    align-items: center;
    background: #696974;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 20px;
    height: 60px;
    margin: 0 auto;
    max-width: 330px;
    padding: 5px 20px;
    text-align: center;
    width: auto;
}
.category{
    display: flex;
    align-items: center;
    color: #171725;
    cursor: pointer;
    font-family: Roboto;
    height: 50px;
    font-size: 20px;
    max-width: 330px;
    padding: 5px 20px;
    text-align: center;
    width: auto;
}