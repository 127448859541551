.wrap_listAuctions{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;

    width: 1176px;

    /* Border gray */
    border-bottom: 1px solid #E2E2EA;
    box-sizing: border-box;
    margin-bottom: 12px;
}

.wrap_listAuctions_topLine, .wrap_listAuctions_bottomLine{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wrap_listAuctions_topLine{
    margin-bottom: 20px;
}

.listAuctions_title{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    /* identical to box height */

    /* Black */
    color: #171725;
}

.wrapButtons{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;

    /* DTO bright blue */
    background: #139DD9;
    border-radius: 4px;

    color: #fff;
    margin-left: 10px;
    cursor: pointer;
}

.statusAuctions{
    width: 285px;
    height: 40px;
    background: #FFFFFF;

    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    /* Black */
    color: #171725;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
}

.tabAuctions{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    /* DTO bright blue */
    color: #139DD9;
    padding-bottom: 24px;
    position: relative;
}

