@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.wrap{
    width: 1176px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 5;
    align-self: stretch;
    flex-grow: 0;
    padding: 32px;
    margin: auto;
    margin-bottom: 16px;
    display: flex;
}

.leftBlock {
    width: 64px;
    height: auto;
    padding-right: 24px;
    position: relative;
}

.centralBlock{
    width: 768px;
    border-right: 1px dashed #E2E2EA;
    padding-right: 24px;
}

.rightBlock{
    width: 240px;
    padding-left: 24px;
}

.unactive{
    width: 64px;
    height: 64px;
    border-radius: 8px;
    overflow: hidden;
    background-image: url(./ukraine.png);
    background-repeat: no-repeat;
}

.avatar {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    overflow: hidden;
    background-color: black;
}

.avatar img {
    opacity: .7;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.sumFoto {
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    position: absolute;
    top: 17px;
    left: 14px;
    user-select: none;
}

.topBlock{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    margin-bottom: 16px;
}

.aucIdAndType{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14.5px;
}

.aucIdAndType_id{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
}

.aucIdAndType_type{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #696974;
}

.aucTitle{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #171725;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 16px;
}

.aucDiscription{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 8px;
}

.aucMore{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #139DD9;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 16px;
}

.bottomBlock{
    display: flex;
    flex-direction: column;
}

.aucClassificatorCode{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    display: inline;
    padding-right: 4px;
}

.aucClassificatorName{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: inline;
    color: #696974;
    margin-top: 4px;
}

.rightBlock_Date{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    padding-top: 6px;
    padding-bottom: 16px;
}

.rightBlock_Start{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
}

.rightBlock_date_text{
    width:100%;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
}

.rightBlock_date_time{
    width:100%;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    color: #696974;
}

.rightBlock_date_timeSpan{
    font-weight: normal;
}

.rightBlock_CostAndButtons{
    width: 100%;
    padding-top: 8px;
}

.costText{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    text-align: right;
    color: #696974;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 8px;
    margin-bottom: 4px;
}

.costSumm{
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    color: #171725;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.buttonsLine{
    margin-top: 16px;
    display: flex;
}

.buttonsLineFavorites{
    width: 40px;
    height: 40px;
    border: 0.5px solid #92929D;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 7px;
    margin-left: 5px;
    margin-right: 8px;
    overflow: hidden;
    cursor: pointer;
}

.buttonsLineFavorites .cheked {
    border: 0.5px solid #FFEF75;
}

.buttonsLineFavorites .active {
    border: 0.5px solid #FFEF75;
}

.buttonGo{
    width: 179px;
    height: 40px;
    padding: 10px 16px;
    background: #139DD9;
    /* background: var(--color_bright_blue); */
    border-radius: 8px;
    border: 1px solid #139DD9;
    box-sizing: border-box;
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 14px;
    color: #FAFAFB;
    text-align: center;
    /* color: var(--color_bg_grey); */
    cursor: pointer;
    transition: .2s;
}
.buttonGo:hover{
    transition: .2s;
    background: #fff;
    color: #139DD9;
    border: 1px solid #139DD9;
}

.buttonGoOff{
    width: 230px;
    height: 40px;
    padding: 10px 16px;
    background: #FAFAFB;
    /* background: var(--color_bright_blue); */
    border: 1px solid #139DD9;
    border-radius: 8px;
    box-sizing: border-box;
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 14px;
    color: #139DD9;
    text-align: center;
    /* color: var(--color_bg_grey); */
    cursor: pointer;
    transition: .2s;
}
.buttonGoOff:hover{
    transition: .2s;
    background: #139DD9;
    color: #fff;
    border: 1px solid #139DD9;
}

.offLink{
    text-decoration: none;
    color: #585858;
    cursor: pointer;
}

.badgeYellow{
    width: 106px;
    height: 22px;

    background: #FFD200;
    border-radius: 4px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #171725;

    display: flex;
    justify-content: center;
    align-items: center;
}
/**STATUS**/
.rightBlock_Status{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    padding-bottom: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
}
.colorLightGrey{
    color: #92929D;
}
.colorDark{
    color: #171725;
}
.colorLightBrown{
    color: #A68800;
}
.colorGreen{
    color: #00B191;
}
.colorMeedllGrey{
    color: #696974;
}
.colorOrange{
    color: #FFA047;
}