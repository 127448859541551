.wrapPages{
    width: 100%;
    background: #fdfefd;
}

.contentPages{
    width: 100%;
}

.content{
    margin: auto;
}

.pagination_wrap{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pagination_wrap div{
    margin-left: 5px;
    border: 1px solid #000;
    padding: 8px;
    cursor: pointer;
}

.activPG{
    color: #000;
    background-color: #ffd200;
}

.pagination_wrap div:hover{
    color: #e3e3e3;
    background-color: #585858;
}

.pagination_firstPage{
    margin-right: 20px;
}

.pagination_lastPage{
    margin-left: 20px !important;
}

.bottonAllAuctions{
    width: 200px;
    margin: auto;
    cursor: pointer;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    background: #ffd200;
    font: 400 20px/20px 'robotoregular',Arial,sans-serif;
    display: block;
    text-align: left;
    padding: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-decoration: none !important;
    position: relative;
    text-align: center;
    margin-bottom: 20px;
}

a{
    text-decoration: none !important;
}

/*@media (min-width: 320px){
    .content{
        width: 300px;
    }
}
@media (min-width: 730px){
    .content{
        width: 710px;
    }
}
@media (min-width: 960px){
    .content{
        width: 940px;
    }
}
@media (min-width: 1280px){*/
    .content{
        /*width: 1260px;*/
        width: 1200px;
    }
/*}
@media (min-width: 1440px){
    .content{
        width: 1420px;
    }
}
@media (min-width: 1600px){
    .content{
        width: 1580px;
    }
}
@media (min-width: 1920px){
    .content{
        width: 1900px;
    }
}*/