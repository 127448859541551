.wrapBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    flex: none;
    order: 0;
    box-sizing: border-box;
    margin: 0 0 20px;
}
.wrapBlock:last-child{
    margin: 0;
}
.wrapBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 20px;
}
.wrapContent{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

/* Buttons */
.blockBtn{
    width: 20%;
    display: flex;
    font-size: 14px;
    flex-flow: row nowrap;
    justify-content: flex-end;
}
.editBtn{
    width: 32px;
    height: 32px;
    display: flex;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s;
}
.editBtn:hover{
    border: 1px solid #139DD9;
    background-color: #139DD9;
    transition: .2s;
}
.editBtn:hover .editIcon{
    fill:#FFF;
    transition: .2s;
}
.deleteBtn{
    width: 32px;
    height: 32px;
    margin: 0 0 0 8px;
    display: flex;
    border: 1px solid #FFA047;
    border-radius: 4px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s;
}
.deleteBtn:hover{
    border: 1px solid #FFA047;
    background-color: #FFA047;
    transition: .2s;
}
.deleteBtn:hover .deleteIcon{
    fill:#FFF;
    transition: .2s;
}