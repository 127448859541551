.wrapNews{
    display: flex;
    width: 240px;
    position: relative;
}

.wrap_link{
    margin-top: 15px;
    padding-left: 15px;
    /*border-left: 1px solid rgb(109, 109, 109);*/
    /*border-left: 1px solid #d9d9d9;*/
    margin-left: 15px;
    position: relative;
}

.wrap_link::after{
    content: ' ';
    position: absolute;
    left: 0px;
    width: 1px;
    top: 0;
    /*bottom: 17px;*/
    height: 45px;
    background: #cacdd1;
    z-index: 10;
}

.korusne_img{
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.korusne_title{
    font: 400 12px/14px "robotobold",Arial,sans-serif;
    color: #414141;
    text-align: left;
    text-transform: uppercase;
    margin-left: 5px;
    font-weight: 600;
}

.korusne_text{
    font: 400 11px/14px "robotolight",Arial,sans-serif;
    color: #666;
    text-align: left;
    text-transform: none;
    padding-left: 10px;
}

a.korusne_link:link, a.korusne_link:visited, a.korusne_link:hover, a.korusne_link:active{
    text-decoration: none;
}

.korusne_link{
    display: flex;
}

.popUp{
    display: none;
}

.popUp a{
    color: #6d6d6d;
}

.wrap_link:hover .popUp{
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    right: -15px;
    z-index: 999;
    background-color: #fff;
}

.popUp_menuElem{
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.popUp_menuElem:hover{
    background-color: silver;
}

.popUp_menuElemEnd{
    border-bottom: 0px solid #000;
}

.svgArrow{
    margin-left: 25px;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}