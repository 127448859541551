.wrapMain{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9;
}
.wrap{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 800px;
    height: auto;
    background-color: #ffffff;
    z-index: 10;
    margin: auto;
    font-size: 14px;
    padding: 24px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}
.close{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    right:20px;
    top:20px;
    font-size: 18px;
    cursor:pointer;
    color:#92929D;
}
.close:hover{
    color:#139DD9;
    transition: .2s;
}
.popupTitle{
    font-size: 24px;
    text-align: left;
}
.description{
    margin: 8px 0 24px;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100% + 20px);
    margin: 10px 0;
    padding: 16px ;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}
.bottomPart{
    height: auto;
    width: 100%;
    /* margin: 24px 0 0; */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
}
.btnDone{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    margin: 0 0 0 20px;
    width: 111px;
    height: 40px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #139DD9;
    cursor: pointer;
    margin: auto;
    margin-top: 20px;
}
.btnDone:hover {
    transition: .2s;
    background-color: #139DD9;
    color: #ffffff;
}
.btnCancel{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 111px;
    height: 40px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    color: #696974;
    cursor: pointer;
}
.btnCancel:hover {
    transition: .2s;
    background-color: #dfdfdf;
    /* color: #ffffff; */
}

/* INPUT */ 
.bidLotCost_descr{
    color: #92929D;
    font-size: 14px;
    margin: 0 0 10px;
}
.bidLotCost_title{
    position: absolute;
    color: #92929D;
    top: -9px;
    left: 10px;
    background-color: #fff;
    padding: 0 5px;
}
.bidLotCost_input{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    color: #6d6d6d;
    font-size: 12px;
    position: relative;
    margin: 10px 0 0;
}

.bidLotCost_input input{
    display: flex;
    align-items: center;
    padding: 9px 12px 8px;
    width: 480px;
    height: 40px;
    border: 1px solid #92929D;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
}
.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.bidLotCost_input input:hover,
.bidLotCost_input input:focus {
    border: 1px solid #139DD9;
    transition: .2s;
    outline: none;
}
.bidLotCost_input input:hover + .bidLotCost_title,
.bidLotCost_input input:focus + .bidLotCost_title{
    color:#139DD9;
    transition: .2s;
}

/**********************************************************************************/
.contractBlock {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.contractBlock_title{
    display: flex;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    height: auto;
}
.dataContractsTitle{
    display: flex;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    height: auto;
    margin: 0 0 20px 0;
}
.slimmer{
    position: absolute;
    right: 24px;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #139DD9;
    cursor: pointer;
}
.contractBlockContent{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 30px 0 0;
}
.dateBetweenBlock{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
.dateBetweenItem{
    display: flex;
    width: 48%;
}

/* DROP-ZONE */

.dropZone{
    width: 100%;
    height: 120px;
    margin: 18px 0 0;
    padding: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px dashed #CED6E0;
    box-sizing: border-box;
    transition: .8s;
}
.dropZoneActive{
    width: 100%;
    height: 120px;
    margin: 18px 0 0;
    padding: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2.5px dashed #139DD9;
    box-sizing: border-box;
    transition: .8s;
}
input[type="file"]{
    display: none;
}
label.btnPin{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 111px;
    height: 32px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #139DD9;
    cursor: pointer;
}
.helpText{
    color:#696974;
}
.btnPin:hover{
    border: 1px solid #139DD9;
    background-color: #139DD9;
    color: #fff;
    transition: .2s;
}
.helpText{
    font-size: 14px;
    text-align: center;
    margin: 0 0 12px;
}
 /* BUTTONS */
 .btnBlock{
     width: 100%;
     display: flex;
     flex-flow: row nowrap;
     justify-content: flex-end;
     margin: 20px 0 0;
 }
 .btnAccept{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: auto;
    height: 40px;
    border: 1px solid #139DD9;
    background-color: #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #FFF;
    cursor: pointer;
    margin: 0 0 0 12px;
}
.btnAccept:hover{
    border: 1px solid #139DD9;
    background-color: #FFF;
    color: #139DD9;
    transition: .2s;
}
 .btnToList{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: auto;
    height: 40px;
    border: 1px solid #E2E2EA;
    /* background-color: #139DD9; */
    border-radius: 4px;
    box-sizing: border-box;
    color: #696974;
    cursor: pointer;
    margin: 0 0 0 12px;
}
.btnToList:hover{
    border: 1px solid #E2E2EA;
    background-color: #E2E2EA;
    color: #696974;
    transition: .2s;
}
/* DOC-ITEM*/
.docItem{
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 18px;
    display: flex;
    flex-flow: row nowrap;
}
.svgIcon{
    display: flex;
}
.docItemTitle{
    display: flex;
    font-size: 14px;
    margin: 0 0 0 15px;
}
.unShiftBtn{
    width: 32px;
    height: 32px;
    border: 1px solid #FFA047;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: auto;
}
.unShiftBtn:hover{
    background-color: #FFA047;
    transition: .2s;
    color: #FFF;
}
.unShiftBtn:hover .svgUnshift{
    fill:#FFF;
    transition: .2s;
}

.baseWrap{
    display: flex;
    width: 100%;
}
.baseWrapHalf{
    width: 50%;
    padding: 4px;
}