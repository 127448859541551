.wrapBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 0 20px;
}
.wrapBlock:last-child{
    margin: 0;
}
.wrapBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}
.wrapContent{
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
}
button.mapAccept{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    color: #fff;
    margin-left: 10px;
    cursor: pointer;
    transition: .2s;
}
button.mapAccept:hover{
    background: #fff;
    border: 1px solid #139DD9;
    color: #139DD9;
    transition: .2s;
}
.preTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin: 15px 0 20px;
}
.halfBlock{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}