.wrap_listAuctions{
    margin: auto auto 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    width: 1176px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
}
.header_line{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
    width: 1168px;
    left: 4px;
    top: calc(50% - 62px/2 - 214px);
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
}
.listAuctions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
    width: 1168px;
    left: 4px;
    top: calc(50% - 62px/2 - 214px);
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
}
.listAuctions:hover{
    border: 1px solid #139DD9;
    transition: .2s;
}

.listAuctions_id{
    display: flex;
    align-items: center;
    width: 80px;
    /*height: 19px;*/

    /* Body */
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    /* Dark Gray */

    color: #696974;
}

.listAuctions_title{
    display: flex;
    justify-content: space-between;
    width: 600px;
    flex-flow: column nowrap;
}
.listAuctions_titleAuc{
    font-weight: 600;
    font-size: 14px;
    overflow-wrap: break-word;
    color: #171725;
}
.listAuctions_id{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    width: auto;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 12px;
    /* padding:  2px 2px 0 8px; */
    /* border: 1px solid #E2E2EA; */
    border-radius: 4px;
    color:#92929D;
}
.listAuctions_type{
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;
    color:#070707;
}
.listAuctions_badge{
    border-radius: 8px;
    color: #696974;
    background-color: #E2E2EA;
    padding: 0 8px;
}

.listAuctions_type_grey{
    color:#696974;

}
.listAuctions_titleLink{
    font-size: 14px;
    color:#070707;
}


.listAuctions_idProzzoro{
    width: 186px;
    height: 19px;

    /* Body */
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */


    /* Dark Gray */
    color: #696974;
}

.listAuctions_status{
    width: 205px;
    /*height: 19px;*/
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-transform: uppercase;

    /* Light gray */
    color: #92929D;
}

.listAuctions_help{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 235px;
    /*height: 38px;*/

    /* Body Link */
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;

    /* DTO bright blue */
    color: #139DD9;
}

.listAuctions_help div{
    margin-right: 10px;
}

.header{
    font-weight: 600;
    color: #000;
    text-decoration: none;
}

.header_line{
    border: 0px solid #fff;
}

.blockMeny{
    width: 40px;
    height: 40px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}