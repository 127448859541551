.mainWrap{
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 20px 0 20px;
    box-sizing: border-box;
}
.wrapBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 20px 0 20px;
}
.wrapBlock:last-child{
    margin: 0;
}
.wrapBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}
.wrapContent{
    display: flex;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}
.warantyBlock{
    width: 50%;
    display: flex;
    flex-flow: column nowrap;
}
.warantyBlockTitle{
    display: flex;
    font-size: 18px;
    font-weight: 600;
}
.warantyDescription{
    display: flex;
    font-size: 14px;
}
.warantyAuto{
    display: flex;
    color: #92929D;
    font-size: 14px;
}
button.editButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 10px 16px;
    background: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    color: #fff;
    margin-left: 10px;
    cursor: pointer;
    transition: .2s;
}
button.editButton:hover{
    background: #fff;
    border: 1px solid #139DD9;
    color: #139DD9;
    transition: .2s;
}
button.cancel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 10px 16px;
    background: #fff;
    border: 1px solid #92929D;
    border-radius: 4px;
    color: #92929D;
    margin-left: 10px;
    cursor: pointer;
    transition: .2s;
}
button.cancel:hover{
    background: #92929D;
    border: 1px solid #92929D;
    color: #fff;
    transition: .2s;
}
.buttonBack{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0 15px;
}


