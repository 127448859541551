.aukcionmenu{
    width: 100%;
    background: #646c7a;
}

.aukcontent{
    display: flex;
    margin: auto;
    color: #fff;
}

.auktionmenublok{
    width: 120px;
    background: #FFF;
    border-top: 3px solid #00aff0;
    color: #646c7a;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 7px;
    border-right: 1px solid #646c7a;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
}

.auktionmenublok_off{
    width: 120px;
    color: #d1d3d7;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    border-right: 1px solid #d1d3d7;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
}

.auktionmenublok_off:last-child{
    border-right: 0px solid #646c7a;
}

@media (min-width: 320px){
    .aukcontent{
        width: 300px;
    }
}

@media (min-width: 730px){
    .aukcontent{
        width: 710px;
    }
}

@media (min-width: 960px){
    .aukcontent{
        width: 940px;
    }
}

@media (min-width: 1280px){
    .aukcontent{
        width: 1260px;
    }
}

@media (min-width: 1440px){
    .aukcontent{
        width: 1320px;
    }
}

@media (min-width: 1600px){
    .aukcontent{
        width: 1480px;
    }
}

@media (min-width: 1920px){
    .aukcontent{
        width: 1800px;
    }
}