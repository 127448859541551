.groupeBank{
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* margin-top: 10px; */
    border-radius: 4px;
    font-weight: 600;
    color: #696974;
    /* border: 1px solid ; */
}

.bankBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #BFBFC6;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 0 10px;
}
.bankBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}
.editBanks{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.bankGroupWrap{
    width: 100%;
    height: auto;
    min-height: 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 15px 10px 10px 10px ;
    background: #FFFFFF;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;
    margin: 40px 0 0px;
}
.bankGroupTitle{
    position: absolute;
    top: -13px;
    left: 9px;
    color: #139DD9;
    font-size: 14px;
    background: inherit;
    padding: 0 5px;
}

.banksNameLine{
    width: 100%;
    display: flex;
    justify-content: space-between
}
.bankWrap{
    width: 100%;
    height: auto;
    min-height: 15px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 5px 8px;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative
}
.bankTmpWrap{
    width: 100%;
    height: auto;
    min-height: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 5px 8px;
    background: #FFFFFF;
    border: 1px dashed #BFBFC6;
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative
}
.bankTmpGroup{
    top: -9px;
    left: 13px;
    font-size: 12px;
    color: #139DD9;
    position: absolute;
    background: #FFFFFF;
    padding: 0 5px;
}
.deleteBtn{
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 30px;
    border: none;
    cursor: pointer;
    background: #FFFFFF;
    transition: .2s;
}
.deleteBtn:hover{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: .2s;
    background: #E2E2EA;
    cursor: pointer;
    border: none;
}

/* VIEW */
.bankAccountsGroups{
    margin: 0 0 30px; 
}

.viewBankGroup{
    width: 100%;
    height: auto;
    min-height: 15px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 5px 8px;
    background: #FFFFFF;
    border: 1px solid #BFBFC6;
    border-radius: 4px;
    box-sizing: border-box;
    justify-content: space-between;
    margin: 0 0 8px;
}
.bankDelete{
    background: #FAFAFB;
    border: 1px dashed #BFBFC6;
    color: #BFBFC6;
}

.bankDelete + .banksNameLine{
    color: #E2E2EA;
}

.bankNew{
    border: 1px dashed #139DD9;
    color: #BFBFC6;
}

/* DELETE */

.btnGroupDelete{
    width: 50px;
    height: 32px;
    display: flex;
    border: 1px solid #FFA047;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 4px;
}
.btnGroupDelete:hover{
    background-color: #FFA047;
    transition: .2s;
}
.btnGroupDelete:hover .btnDeleteSVG{
    fill: #fff;
    transition: .3s;
}
.btnGroupDeleteCancel{
    width: 50px;
    height: 32px;
    display: flex;
    border: 1px solid #BFBFC6;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #fff;
    /* color: #92929D; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 4px;
}
.btnGroupDeleteCancel:hover{
    background-color: #BFBFC6;
    transition: .2s;
}
.btnGroupDeleteCancel:hover .btnCancelSVG{
    fill: #696974;
}
.svgIcon{
    display: flex;
}
.btnGroupDeleteDescription{
    font-weight: normal;
    font-size: 12px;
    padding: 0 8px;
    text-align: center;
    color: #BFBFC6;
}