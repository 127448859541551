@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/*  */ 
.sideBlock {
    width: 370px;
    height: auto;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 24px 0 0;
}
.sidelotImage{
    /*display: ;*/
    width: 100%;
}
.sidelotImage img{
    width: 150px;
    height: 150px;
    border-radius: 4px;
    cursor: pointer;
    object-fit:scale-down;
}
.aucType{
    font-size: 24px;
    font-weight: 600;
}
.aucStatus{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 15px;
}
.aucStatus p {
    margin-bottom: 0px;
}
p.lotStatus{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 8px;
    color: #00B191;
}
.lotStartPriceBlock{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 17px 0 20px;
}
.lotStartPriceBlock p{
    margin: 0 0;
}
p.lotStartPrice{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
    margin: 0 0;
}
.lotStartPrice span{
    font-size: 14px;
    color: #171725;
}
/* STEPS */
.stepBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;
    margin: 25px 0 5px 0;
}
.auctionStepsTitle{
    font-size: 24px;
    font-weight: 600;
}
.stepTitle{
    display: flex;
    padding: 3px 0 0;
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 4px ;
}
.stepDate {
    font-size: 14px;
}
.stepIcon{
    width: 36px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    box-sizing: border-box;
    padding-top: 0px;
}
.stepInfo{
    width: 266px;
    padding-left: 24px;
    box-sizing: border-box;
}
.stepInfo h3{
    margin: 0px 0 5px 0;
    width: 266px;
}
.stepInfo h3.inactive{
    color:#92929D;
    font-weight: 600;
}
.stepInfo p{
    font-size: 14px;
    color: #92929D;
    margin: 0px 0 0 0;
}
.balance{
    margin-top: 30px;
}
.balance p {
    width: 322px;
    font-size: 14px;
    line-height: 19px;
    color: #171725;
    margin: 0;
}
.balance h2 {
    margin: 0 0 10px 0;
}
.balanceBtn{
    width: 320px;
    height: 40px;
    border: 1px solid #139DD9;
    color: #139DD9;
    margin: 25px 0 0 0px;
    justify-self: flex-start;
}
.unActive{
    color: #92929D;
}

.buttonLink{
    width: 300px;
    height: 40px;
    background: #139DD9;
    border-radius: 4px;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    text-align: center;
    color: #FAFAFB;
    cursor: pointer;
    margin: auto;
    margin-top: 20px;
}

.lineButtonLink{
    width: 320px;
}
/*  IMAGE POPUP  */
.imageWrapper{
    display: flex;
    position: relative;
    margin: 0 15px;
    width:auto;
    height: auto;
    /* max-width: 1000px; */
    max-height: 800px;
    padding:8px;
    z-index: 999;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;     
}
.image{
    position: relative;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
    max-height: 800px;
}
.image img{
    max-height: 800px;
}
@media (max-height: 768px){
    .image{
    position: relative;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
    max-height: 440px;
}
.image img{
    max-height: 440px;
}
}
.imageWrapperBg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background:rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.noDisplay{
    display: none;
}
.popUpClose{
    position: absolute;
    top: -38px;
    right: -60px;
    font-size: 64px;
    cursor: pointer;
    color:#ffffff;
    opacity: .3;
}
.popUpClose:hover{
    opacity: 1;
    transition: .2s;
}
.imageArrow{
    height: 300px;
    width:40px;
    display: flex;
    font-size: 62px;
    cursor: pointer;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    opacity: .3;
}
.imageArrowPlaceholder{
    height: 300px;
    width:40px;
    display: flex;
    font-size: 62px;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    opacity: .3;
}
.imageArrow:hover{
    opacity: 1;
    transition: .2s;
}