.wrapMain{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9998;
}
.wrap{
    display: flex;
    flex-direction: column;
    width: 380px;
    max-height: 90vh;
    background-color: #ffffff;
    z-index: 9999;
    margin: auto;
    padding: 32px;
    border-radius: 10px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}
.popUpTitle{
    padding: 0 16px;
    margin-top: 28px;
    text-align: center;
    height: auto;
    min-height: 60px;
    font-weight: 600;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100% + 20px);
    margin: 10px 0;
    padding: 16px ;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}
.bottomPart{
    height: 56px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
    box-sizing: border-box;
}
.btnDone{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 111px;
    height: 40px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #139DD9;
    cursor: pointer;
}
.btnDone:hover {
    transition: .2s;
    background-color: #139DD9;
    color: #ffffff;
}
.btnCancel{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 111px;
    height: 40px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    color: #696974;
    cursor: pointer;
}
.btnCancel:hover {
    transition: .2s;
    background-color: #dfdfdf;
    /* color: #ffffff; */
}