.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    padding: 40px;
    background-color: #fff;
    text-align: center;
    max-width: 600px;
    position: relative;
}

.popup_message div{
    text-align: left;
    color: #6d6d6d;
}

.popUpExit{
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
}

.popup_message_button{
    color: #fff !important;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center !important;
}

.popUpTitle{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center !important;
    color: #000 !important;
}

.inputWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    color: #6d6d6d;
}

.inputWrap div{
    margin-right: 30px;
}
.inputWrap input{
    width: 300px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
}