.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    padding: 40px;
    background-color: #fff;
    text-align: center;
    position: relative;
    z-index: 9999;
}

.popup_message_button{
    color: #fff;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    border: 0px solid #000;
    width: 100%;
}

.pseudoCheckboxOn{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
}
.pseudoCheckboxOff{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    background-color: black;
}

.popup_closeX{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
    background-color: #eee;
}

.title{
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 20px;
}

.groupElem{
    margin-bottom: 20px;
}