button:active{
    -webkit-box-shadow: inset 0px 0px 15px -10px #646464; 
    box-shadow: inset 0px 0px 15px -10px #646464;
}
button{
    user-select: none;
}
button.blue{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 10px 16px;
    background: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: .2s;
}
button.blue:hover{
    background: #fff;
    border: 1px solid #139DD9;
    color: #139DD9;
    transition: .2s;
}

button.gray{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 10px 16px;
    background: #fff;
    border: 1px solid #92929D;
    border-radius: 4px;
    color: #92929D;
    outline: none;
    cursor: pointer;
    transition: .2s;
}
button.gray:hover{
    background: #92929D;
    border: 1px solid #92929D;
    color: #fff;
    transition: .2s;
}

button.white{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 10px 16px;
    background: #fff;
    border: 1px solid #139DD9;
    border-radius: 4px;
    color: #139DD9;
    cursor: pointer;
    transition: .2s;
}
button.white:hover{
    background: #139DD9;
    border: 1px solid #139DD9;
    color: #fff;
    transition: .2s;
}
button.disabled{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 10px 16px;
    background: #BFBFC6;
    border: 1px solid #BFBFC6;
    border-radius: 4px;
    color: #ffffff;
    outline: none;
    cursor: default;
    transition: .2s;
}
.circle{
    border-radius: 50%;
}