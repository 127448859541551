.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    padding: 40px;
    background-color: #fff;
    text-align: center;
}

.popup_message_button{
    color: #fff;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    border: 0px solid #000;
    width: 100%;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;

    /* DTO bright blue */
    background: #139DD9;
    border-radius: 4px;

    color: #fff;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 20px;
}