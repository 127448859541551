/* LOT COMPOSITION */

.infoSubBlock {
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 10px 0;
    padding:24px 24px 12px;
    display: flex;
    flex-flow: row wrap;
}
.infoSubBlock:last-child{
    margin-bottom: 0;
}
/* Buttons */
.btnBlock{
    display: flex;
    justify-items: flex-end;
}
.downLoadBtn, .btnEdit {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 4px;
}
.downLoadBtn:hover,.btnEdit:hover, .downLoadAllBtn:hover {
    border: 1px solid #139DD9;
    background-color:  #139DD9;
    transition: .2s;
}
.downLoadBtn:hover .svgDownld,
.btnEdit:hover .btnEditSVG {
    fill:#fff;
    transition: .2s;
}
.historyBtn{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 4px;
}
.historyBtn:hover{
    border: 1px solid #00B191;
    background-color:  #00B191;
    transition: .2s;
}
.historyBtn:hover .svgDownld {
    fill:#fff;
    transition: .2s;
}
.btnDelete{
    width: 32px;
    height: 32px;
    display: flex;
    border: 1px solid #FFA047;
    box-sizing: border-box;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 4px;
}
.btnDelete:hover{
    background-color: #FFA047;
    transition: .2s;
}
.btnDelete:hover .btnDeleteSVG{
    fill: #fff;
    transition: .3s;
}
.svgIcon{
    display: flex;
}

ul.fileList{
    padding:0px;
    margin:0px 0;
    font-size: 14px;
    color: #171725;
}
ul.fileList li{
    display: inline-block;
    cursor: pointer;
    color:#139DD9;
    text-decoration: underline;
}
.lotImageWrap{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.lotImage{
    position: relative;
    width: 150px;
    height: 150px;
}
.lotImage p{
    font-size: 14px;
    color:#139DD9;
    cursor: pointer;
    text-decoration: underline;
}
.lotImage img{
    width: 150px;
    height: 150px;
    border-radius: 4px;
    cursor: pointer;
    object-fit:scale-down; 
}
.imgHover{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.imgHover:hover{
    display: flex;
    z-index: 99;
    background-color: rgba(146, 146, 157, .5);
    background-position: 0% 0%;
    background-repeat: no-repeat;
    border-radius: 4px;
    transition:.2s;
}
.imgHover:hover .zoomIcon{
    opacity: 1;
    transition: .4s;
}
.titleBlock{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
.infoBlockTitle{
    color: #171725;
    width:70%;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    overflow-wrap: anywhere;
}
.zoomIcon{
    position: relative;
    display:flex;
    opacity: 0;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
    background:#139DD9;
    border-radius:50%;
}

/* IMAGE DOC ITEM */

.imageItemBlock{
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 10px 0;
    padding: 24px;
    display: flex;
    flex-flow: column nowrap;
}
.imageTitleBlock{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 0 0 15px 0;
}
.imageContentBlock{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
.imageProps{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: space-between;
    justify-content: space-between;
}
.imgContainer{
    width: 45%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}

/*  IMAGE POPUP  */
.imageWrapper{
    display: flex;
    position: relative;
    margin: 0 15px;
    width:auto;
    height: auto;
    max-height: 800px;
    padding:8px;
    z-index: 999;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;     
}
.image{
    position: relative;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
    max-height: 800px;
}
.image img{
    max-height: 800px;
}
@media (max-height: 768px){
    .image{
    position: relative;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
    max-height: 440px;
}
.image img{
    max-height: 440px;
}
}
.imageWrapperBg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background:rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.popUpClose{
    position: absolute;
    top: -36px;
    right: -60px;
    font-size: 64px;
    cursor: pointer;
    color:#ffffff;
    opacity: .3;
}
.popUpClose:hover{
    opacity: 1;
    transition: .2s;
}
