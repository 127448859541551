@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.sideBlock {
    width: 370px;
    height: auto;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    /* background-color: forestgreen; */
    padding: 24px 0 0 24px;
}