.find{
    width: 100%;
}

/*@media (min-width: 320px){
    .find_wrap{
        width: 300px;
        margin: auto;
    }
}
@media (min-width: 730px){
    .find_wrap{
        width: 710px;
        margin: auto;
    }    
}
@media (min-width: 960px){
    .find_wrap{
        width: 940px;
        margin: auto;
    }
}
@media (min-width: 1280px){*/
    .find_wrap{
        /*width: 1260px;*/
        /*width: 1200px;
        margin: auto;*/
    }
/*}
@media (min-width: 1440px){
    .find_wrap{
        width: 1320px;
        margin: auto;
    }
}
@media (min-width: 1600px){
    .find_wrap{
        width: 1480px;
        margin: auto;
    }
}
@media (min-width: 1920px){
    .find_wrap{
        width: 1800px;
        margin: auto;
    }
}*/