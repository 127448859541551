.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
    backdrop-filter: blur(1px) grayscale(60%);
}
.popup_message{
    display: flex;
    flex-flow: column nowrap;
    padding: 40px;
    background-color: #fff;
    text-align: center;
    max-width: 500px;
    min-width: 380px;
    position: relative;
    box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
    border-radius: 10px;
}
.popUpExit{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    transition: .2s;
}
.popUpExit:hover .closeSVG{
    fill:rgb(0, 175, 240);
    transition: .2s;
}
.logInPut{
    display: flex;
    width: 100%;
    height: 43px;
    border: 1px solid #92929D;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 8px;
    transition: .2s;
}
.logInPut:hover,
.logInPut:active,
.logInPut:focus{
    border: 1px solid rgb(0, 175, 240);
    outline: none;
    transition: .2s;
}
.logInPut:hover + .logInPutLable,
.logInPut:active + .logInPutLable,
.logInPut:focus + .logInPutLable{
    color:rgb(0, 175, 240);
    transition: .2s;
}
.logInPutLable{
    /* display: flex; */
    position: absolute;
    width: auto;
    text-transform: capitalize;
    top: -5px;
    left: 10px;
    font-size: 12px;
    background-color: #fff;
    padding: 0px 6px;
    border-radius: 4px;
}
.popup_message_button{
    display: flex;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    background-color: rgb(0, 175, 240);
    border: 1px solid rgb(0, 175, 240);
    box-sizing: border-box;
    height:37px;
    width: 60%;
    margin-top: 24px;
    border-radius: 4px;
    padding: 10px 24px;
    text-transform: uppercase;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: .2s;
    align-self: center;

}
.popup_message_button:hover{
    color:rgb(0, 175, 240);
    border: 1px solid rgb(0, 175, 240);
    background-color: rgb(255, 255, 255);
    transition: .2s;
}
.popUpTitle{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center !important;
    color: #000 !important;
}
.inputWrap{
    display: flex;
    position: relative;
    flex-flow: column;
    justify-content: space-between;
    margin-top: 15px;
    color: #6d6d6d;
}