.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    padding: 40px 40px 25px;
    display: flex;
    background-color: #fff;
    font-weight: 600;
    border-radius: 8px;
    position: relative;
    z-index: 9999;
    flex-direction: column;
    align-items: center;
}
.buttonClose{
    display: flex;
    width: auto;
    border: 1px solid rgb(0, 175, 240);
    box-sizing: border-box;
    border-radius: 4px;
    margin: 25px 0 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px 16px;
    color:rgb(0, 175, 240);
    transition: .2s;
}
.buttonClose:hover{
    border: 1px solid rgb(0, 175, 240);
    background-color: rgb(0, 175, 240);
    color: #FFF;
    transition: .2s;
}

.popup_message_button{
    color: #fff;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    margin-left: 10px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    border: 0px solid #000;
    width: 200px;
}
.popup_message_buttonOff{
    background-color: #eee;
    color: #000;
}

.popup_closeX{
    position: absolute;
    display: flex;
    top: 5px;
    right: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: .2s;
    align-items: center;
    justify-content: center;
}

.popup_closeX:hover .popup_closeSvg{
    fill: rgb(0, 175, 240);
    transition: .2s;
}

.documentsBlock{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.mainDocumentsBlock{
    height: 300px;
    overflow: auto;
}

.uploadDoc{
    display: flex;
    justify-content: space-between;
}