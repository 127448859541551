.preTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
    margin: 15px 0 20px;
}
.lineButtons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.headers{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.twoInLine{
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}
