.documentWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.flexBlock{
    width: 100%;
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around; 
}

.flexBlockElem{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auctionsLine{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c5c5;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
}

.documentsForm{
    width: 95%;
}

.auctionsLineElem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

div .inputText, div .selectWidth{
    margin-bottom: 10px;

}

.inputText, .selectWidth{
    width: 100%;
}

.buttonExitDocument{
    width: 300px;
}

.editButton{
    margin-right: 25px;
}

.lineButtons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.headers{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
   
   
.input__file_button_text {
    line-height: 1;
    margin-top: 1px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 20px;
}

.button{
    appearance: none;
    max-width: 280px;
    height: 60px;
    border: 0;
    background: #4676D7;
    color: #fff;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
}

.formLine{
    margin-top: 20px;
    margin-bottom: 30px;
}

.input__wrapper{
    height: 60px;
}

.documentsId{
    margin-right: 15px;
}

.documentInfoBlock{
    border: 1px solid #000;
    padding: 3px;
    margin: 2px;
}

.documentInfoBlock_text{
    font-size: 16px;
    font-weight: 500;
}

.documentInfoBlock_text span{
    font-size: 14px;
    font-weight: 400;
    color: darkslategray;
}

/**************************/
.lineWrap{
    border: 1px solid #000;
    margin: 2px;
    padding: 3px;
    display: flex;
    font-size: 14px;
}

.lineWrap .line{
    justify-content: space-between;
}

.lineWrap .line div{
    width: 90%;
}

.lineDiscrip{
    width: 100%
}

/*8888888888888888*/
.h3Title{
    padding-left: 10px;
}

.lineWrapAwards{
    width: 100%;
    display: flex;
    color: #000 !important;
    font-size: 12px !important;
}

.lineWrapAwards_text{
    padding: 10px;
}

.lineWrapAwards_title{
    width: 150px;
    font-weight: 600;
}

.lineWrapAwards_titleContract{
    width: 300px;
    font-weight: 600;
}

.lineWrapAwards_content{
    width: 300px;
    overflow: hidden;
}

.lineWrapAwards_contentDate{
    width: 150px;
    overflow: hidden;
}

.buttonsContract{
    margin-bottom: 10px !important;
    width: 250px;
}

.lineBids_userDocumentHis{
    text-decoration: line-through;
}

.notCanter{
    text-align: left !important;
}

.blockButton{
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
}