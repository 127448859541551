@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.lotStartPriceBlock{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 17px 0 20px;
}
.lotStartPriceBlock p{
    margin: 0 0;
}
p.lotStartPrice{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
    margin: 0 0;
}
.lotStartPrice span{
    font-size: 14px;
    color: #171725;
}