.inPutWrap{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    position:relative;
    margin: 5px 0 20px;
}
.inPutSelect {
    width: 120px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    background-color: #FAFAFB;
    outline:none;
    font-size: 14px;
    color: #92929D;
    padding: 10px 10px 10px 5px;
    position: relative;
    cursor: pointer;
    transition: .5s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.title{
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    margin: 5px 0px 20px;
}
.content{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 20px;
}
.alert{
    width: 120px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    background-color: #FFA047;
    outline:none;
    font-size: 14px;
    color: #92929D;
    padding: 10px 10px 10px 5px;
    position: relative;
    cursor: pointer;
    transition: .5s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.alert + .label{
    color: #FFA047;
}
select.inPutSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.inPutSelectWrap{
    position: relative;
    width: 120px;
}
.inPutSelectWrap:after{
    position: absolute;
    border: 6px solid transparent; 
    border-top: 6px solid #92929D;
    top:18px;
    right: 8px;
    font-size: 16px;
    content: ' ';
    color: #92929D;
}
.label{
    position: absolute;
    bottom: 31px;
    left: 150px;
    padding: 2px 4px;
    background-color:#ffffff;
    color: #92929D;
    border-radius: 8px;
    font-size: 11px;
    z-index: 22;
}
.labelCur{
    position: absolute;
    top: -9px;
    left: 9px;
    padding: 2px 4px;
    background-color:#ffffff;
    color: #92929D;
    border-radius: 8px;
    font-size: 11px;
}
.disabled,
.inPutAmount{
    width: 300px;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #171725;
    padding: 0px 10px 0 8px;
    box-sizing: border-box;
}
.disabled{
    color: #696974;
}
.inPutAmount:hover,
.inPutAmount:focus{
    border: 1px solid #00AFF0;
    transition: .2s;
}
.inPutAmount:hover + .label,
.inPutAmount:focus  + .label{
    color: #00AFF0;
    transition: .2s;
}
.inPutAmount::-webkit-outer-spin-button,
.inPutAmount::-webkit-inner-spin-button {
    -webkit-appearance: none;
}