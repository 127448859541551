.bankBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #BFBFC6;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 0 10px;
}
.bankBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}
.arrestLine{
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}
.arrestLineElem{
    width: calc(100% - 32px);
    padding: 8px 16px;
}
.checkbox{
    font-size: 12px;
}

.banksNameLine{
    /*width: 100%;*/
    display: flex;
    justify-content: space-between;
    border: 1px solid #139DD9;
    padding: 15px 10px 10px 10px ;
    border-radius: 4px;
    margin-bottom: 10px;
}

.deleteBtn{
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 30px;
    border: none;
    cursor: pointer;
    background: #FFFFFF;
    transition: .2s;
}
.deleteBtn:hover{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: .2s;
    background: #E2E2EA;
    cursor: pointer;
    border: none;
}

.banksWrap{
    width: calc(100% - 32px);
    padding: 8px 16px;
}

.editBanks{
    display: flex;
    width: 100%;
    justify-content: space-between;
}