.wrap_auc{
    width: 1200px;
}

.wrap_auc_tab{
    width: 100%;
}

.auc_line{
    display: flex;
    margin-bottom: 5px;
}

.auc_line:nth-child(even){
    background-color: #c5c5c5;
}

.baseData{
    border: 1px solid #c5c5c5;
    font-size: 12px;
    text-align: center;
}

.auc_line:nth-child(even) div{
    border: 1px solid #fff;
}

.auc_line:hover div{
    background-color: #5d8aa8;
}

.auc_line:last-child{
    margin-bottom: 25px;
}

.lotNumber{
    width: 30px;
}
.pothishn{
    width: 150px;
}
.name{
    /*width: 120px;*/
    width: 190px;
}
.poroda{
    width: 120px;
}
.sort{
    width: 90px;
}
.sklad{
    width: 50px;
}
.diametr{
    width: 120px;
}
.dovjina{
    width: 70px;
}
.obem{
    width: 60px;
}
.cina{
    width: 60px;
}
.summa{
    width: 64px;
}
.sellers{
    width: 150px;
}
.buttonsBlock{
    width: 90px;
}


.pagination_wrap{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pagination_wrap div{
    margin-left: 5px;
    border: 1px solid #000;
    padding: 8px;
    cursor: pointer;
}

.activPG{
    color: #000;
    background-color: #ffd200;
}