.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9990;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(1px) grayscale(60%);
}
.popup_message{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 1200px;
    max-height: 80vh;
    background-color: #ffffff;
    z-index: 9999;
    margin: auto;
    font-size: 14px;
    padding: 24px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    transition: .8s;
}
.close{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    right:20px;
    top:20px;
    font-size: 18px;
    cursor:pointer;
    color:#92929D;
    transition: .2s;
}
.close:hover .closeSVG{
    fill:#139DD9;
    transition: .2s;
}
.close:hover{
    color:#139DD9;
    transition: .2s;
}
.mainDocumentsBlock{
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    gap: 5px;
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.titleBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 30px 0 20px;
    font-size: 22px;
    font-weight: 600;
}

.bankAccountHeader{
    display: inline-grid;
    align-items: center;
    grid-template-columns: 3% 14% 15% 20% 24% 23% 1%;
    font-weight: 600;
    color: #696974;
    padding: 8px 14px 8px;
    border-radius: 8px;
    border-bottom: 1px solid #E2E2EA;
    margin-bottom: 5px;
    background-color: #E2E2EA;
}

.bankAccountElement{
    display: grid;
    align-items: center;
    height: auto;
    min-height: 50px;
    padding: 5px;
    grid-template-columns: 3% 14% 15% 19% 24% 19% 5%;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid #E2E2EA;
    transition: .2s;
}
.bankAccountElement:hover{
    transition: .2s;
    border: 1px solid #139DD9;
}
.editButton{
    display: flex;
    border-radius: 50%;
    background-color: #FAFAFB;
    width: 34px;
    height: 34px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-sizing: border-box;
    text-align: right;
}
.editButton:hover{
    cursor: pointer;
    background-color: #139DD9;
    transition: .2s;
}
.editButton:hover .editSVG{
    fill:#FFF;
    transition: .2s;
}
/* .close:hover{
    color:#139DD9;
    transition: .2s;
} */

.pn{
    color:#fff;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color:#92929D; */
    text-align: center;
    border-radius: 50%;
    background-color: #92929D;
}

/* .bankTitle{
    text-align: left;
}

.bankDescription{
    text-align: left;
}

.accountIdentificationsDescription{
    text-align: left;
}

.accountIdentificationsIdentifier{
    text-align: left;
}

.accountIdentificationsShema{
    text-align: left;
}

.buttonExitDocument{
    margin-left: 10px !important;
} */