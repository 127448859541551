.newQuestionBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 24px;    
    position: static;
    width: calc(100% - 50px);
    height: auto;
    background: #ffffff;
    border: 1px solid #00AFF0;
    border-radius: 4px;
    margin: 0 auto;
}
.title{
    margin: 10px 0 25px;
    color:#171725;
}
.inputStyle{
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #92929D;
    padding: 0px 10px 0 10px;
    box-sizing: border-box;
}
.inputStyle:hover,
.inputStyle:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.textArea{
    width: 100%;
    height: 130px;
    font-size: 14px;
    font-family: inherit;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #92929D;
    padding: 8px 10px 0 10px;
    box-sizing: border-box;
    resize: none;
    margin-top: 30px;
}
.textArea:hover,
.textArea:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
::-webkit-input-placeholder {
    color:#92929D;
    font-size: 14px;}
::-moz-placeholder          {
    color:#92929D;
    font-size: 14px;}/* Firefox 19+ */
:-moz-placeholder           {
    color:#92929D;
    font-size: 14px;}/* Firefox 18- */
:-ms-input-placeholder      {
    color:#92929D;
    font-size: 14px;}
.blueBtn{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    font-size: 14px;
    font-weight: 600;
    font-family: inherit;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    position: static;
    width: 98px;
    height: 40px;
    background: #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 18px;
    color: #ffffff;
    cursor: pointer;
}
