.logo_wrap div img{
    width: 80px;
}

/*@media (min-width: 320px){
    .titletext{
        font: 700 10px/11px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .bootontext{
        font: 400 9px/10px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        font-weight: 600;
    }
    .logo_wrap{
        display: flex;
        padding-top: 15px;
        padding-bottom: 25px;
    }
}

@media (min-width: 730px){
    .titletext{
        font: 700 10px/11px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .bootontext{
        font: 400 9px/10px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        font-weight: 600;
    }
    .logo_wrap{
        display: flex;
        padding-top: 15px;
        padding-bottom: 25px;
    }
}

@media (min-width: 960px){
    .titletext{
        font: 700 18px/22px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .bootontext{
        font: 400 11px/14px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        font-weight: 600;
    }
    .logo_wrap{
        display: flex;
        padding-top: 15px;
        padding-bottom: 25px;
    }
}

@media (min-width: 1280px){*/
    .titletext{
        font: 700 18px/22px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .bootontext{
        font: 400 11px/14px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        font-weight: 600;
    }
    .logo_wrap{
        display: flex;
        padding-top: 15px;
        /*padding-bottom: 25px;*/
    }
/*}

@media (min-width: 1440px){
    .titletext{
        font: 700 18px/22px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .bootontext{
        font: 400 11px/14px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        font-weight: 600;
    }
    .logo_wrap{
        display: flex;
        padding-top: 15px;
        padding-bottom: 25px;
    }
}

@media (min-width: 1600px){
    .titletext{
        font: 700 18px/22px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .bootontext{
        font: 400 11px/14px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        font-weight: 600;
    }
    .logo_wrap{
        display: flex;
        padding-top: 15px;
        padding-bottom: 25px;
    }
}

@media (min-width: 1920px){
    .titletext{
        font: 700 18px/22px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .bootontext{
        font: 400 11px/14px "robotobold",Arial,sans-serif;
        margin-left: 5px;
        font-weight: 600;
    }
    .logo_wrap{
        display: flex;
        padding-top: 15px;
        padding-bottom: 25px;
    }
}*/

.titletext{
    color: #000;
    text-transform: uppercase;
    letter-spacing: -0.1px;
}
.bootontext{
    color: #00aff0;
    text-transform: none;
}

a{
    cursor: pointer;
}

a.logoA:link, a.logoA:visited, a.logoA:hover, a.logoA:active{
    text-decoration: none;
}

