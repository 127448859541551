@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* LOT COMPOSITION */
.blockHalf {
    width:50%;
    margin: 0 0 12px;
    padding: 0 30px 0 0;
    box-sizing: border-box;
}
.blockFull {
    width:100%;
    margin: 0 0 12px;
    padding-right: 20px;
    box-sizing: border-box;
}
.description {
    font-family: 'Segoe UI';
    font-size: 11px;
    line-height: 14px;
    color: #696974;
    margin: 2px 0 0;
}
.insideInfo, .insideInfoDisabled{
    margin: 4px 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #171725;
    word-break: break-word;
}
.insideInfoDisabled{
    color: #BFBFC6;
    text-decoration: line-through;
}
li{
    list-style: none;
}
.insideInfo p a, .insideInfo p a{
    text-decoration: underline !important;
    font-weight: 600;
    color: #00aff0 !important;
}