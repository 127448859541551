.wrap{
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

td{
    border: 1px solid #000;
}

.auctionsLineElem{
    /*display: flex;*/
    font-size: 14px;
    width: 20%;
}

.chekbox,
.seller,
.linkToAuction,
.sortment,
.species,
.class,
.length,
.storage,
.quantity,
.baseCoast,
.bid,
.auctionStatus,
.bidStatus,
.addDoc,
.buttonDoc,
.bidStart,
.bidDel{
    width: 30px !important;
    font-size: 10px;
    border: 1px solid #000;
    margin: 1px;
    padding: 1px;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: center;
}

.obratu{
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: center;
}

.chekbox{
    display: flex;
    justify-content: center;
    align-items: center;
}

.seller{
    width: 100px !important;
}

.sortment,
.species,
.class,
.length,
.storage,
.quantity,
.baseCoast,
.bid,
.auctionStatus,
.bidStatus,
.addDoc{
    width: 76px !important;
}

.buttonDoc,
.bidStart{
    width: 110px !important;
}

.bidDel{
    width: 50px !important;
}

.linkToAuction{
    width: 48px !important;
}

.pseudoCheckboxOn{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
}
.pseudoCheckboxOff{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    background-color: black;
}

.pseudoCheckboxOff:hover{
    background-color: black !important;
}

.massButtonBlock{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.filterBlock{
    display: flex;
    justify-content: center;
    width: 1200px;
    margin-bottom: 25px;
}

.filterImputBlock{
    display: flex;
    flex-direction: column;
    width: calc(400px - 40px);
    padding: 20px;
}

.filterImputBlock_input{
    margin-bottom: 15px !important;
}

.filterBlock:nth-child(1){
    margin-bottom: 0px !important;
}

.filterImputBlock_button{
}

.buttonsTabAuctionPull{
    display: flex;
    justify-content: center;
}

.buttonsTabAuctionPull_buttons{
    margin-right: 25px !important;
}





.auc_line{
    display: flex;
    margin-bottom: 5px;
}

.auc_line:nth-child(even){
    background-color: #c5c5c5;
}

.baseData{
    border: 1px solid #c5c5c5;
    font-size: 12px;
    text-align: center;
}

.auc_line:nth-child(even) div{
    border: 1px solid #fff;
}

.auc_line:hover div{
    background-color: #5d8aa8;
}

.auc_line:hover div .pseudoCheckboxOff{
    background-color: black !important;
}

.auc_line:last-child{
    margin-bottom: 25px;
}

.buttonElement{
    font-size: 12px !important;
}


/*@media (min-width: 320px){
    .wrap{
        width: 300px;  
    }
}
@media (min-width: 730px){
    .wrap{
        width: 710px;  
    }
}
@media (min-width: 960px){
    .wrap{
        width: 940px;  
    }
}
@media (min-width: 1280px){*/
    .wrap{
        /*width: 1260px;  */
        width: 1200px;
    }
/*}
@media (min-width: 1440px){
    .wrap{
        width: 1320px;  
    }
}
@media (min-width: 1600px){
    .wrap{
        width: 1480px;  
    }
}
@media (min-width: 1920px){
    .wrap{
        width: 1800px;  
    }
}*/

.onlineAuctionElem{
    width: calc(100% - 20px);
    /*width: calc(25% - 20px);*/
    border: 1px solid #000;
    border-radius: 4px;
    margin: 5px;
    padding: 4px;
    position: relative;
    overflow: hidden;
    height: 600px;
    font-size: 13px;
}

.buttonsBlock{
    margin-bottom: 40px;
    margin-top: 10px;
}

.infoBlock{
    /*position: absolute;
    bottom: -240px;
    left: 0;
    right: 0;*/
    background-color: #c5c5c5;
    cursor: pointer;
    padding: 5px;
}

.infoBlock1{
    position: absolute;
    bottom: -210px;
    left: 0;
    right: 0;
    background-color: #c5c5c5;
    cursor: pointer;
    padding: 5px;
}

.infoBlock_title{
    text-transform: uppercase;
    text-align: center;
}

.infoBlock1:hover{
    top: 0px;
    bottom: 0px;
    z-index: 555;
}

.bidersLine{
    display: flex;
}

.bidersLine_count{
    width: 30px;
    border-right: 1px solid #000;
}

.bidersLine_buyer{
    width: 300px;
    padding-left: 10px;
}

.bidersLine_buyer span{
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #000;
}

.hodStavki{
    font-weight: 600;
    background-color: forestgreen;
    line-height: 30px;
    padding: 5px;
}

.allActiveAuctions{
    display: flex;
    flex-wrap: wrap;
}

.buttonStart{
    width: calc(100% - 10px) !important;
    border: 1px solid #000 !important;
    border-radius: 4px !important;
    margin: 5px !important;
    padding: 4px !important;
}
.buttonActiveAuc{
    font-size: 12px !important;
    padding: 2px !important;
    margin-right: 5px !important;
}