/* JUST INPUT */
.inPutInfo{
    width: 100%;
    display: flex;
    position:relative;
    margin: 0 0 15px;
}

input.inPut {
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #171725;
    padding: 0px 10px 0 5px;
    box-sizing: border-box;
}
input.inPut::placeholder{
    width: 100%;
    height: 40px;
}
.focus,
input.inPut:hover,
input.inPut:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.inPut{
    color: #171725;
}
.half{
    width:49%;
}
.center{
    align-items: center;
}
.label{
    position: absolute;
    left: 8px;
    top: -5px;
    font-size: 11px;
    line-height: 9px;
    padding: 0px 5px;
    color: #92929D;
    background-color: #FFFFFF;;
    z-index: 2;
}
.focus + .label,
.inPut:hover + .label,
.inPut:focus + .label {
    color:#00AFF0;
    transition: .2s;
}
.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: 0px 0 8px 10px;
    position: relative;
}
.alert{
    border: 1px solid #FFA047;
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border-radius: 4px;
    position: relative;
    color: #171725;
    padding: 0px 10px 0 5px;
    box-sizing: border-box;
}
.alert + .label{
    color: #FFA047;
}
.infoIcon{
    height: inherit;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    cursor: pointer;
    position: relative;
}
.infoIcon:hover .svgInfo{
    fill:#139DD9;
    transition: .2s;
}

.noDisplay{
    display:none;
}