.fullWidth {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 10px;
}
.halfWidth {
    width: 48%;
}
.period {
    /* background: #FFFFFF; */
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    padding: 24px;
    position: relative;
    margin-bottom: 10px;
    position: relative;
}
.periodLabel{
    position: absolute;
    max-width:75%;
    left: 18px;
    top: -6px;
    font-size:11px;
    line-height: 9px;
    padding: 1px 5px;
    color: #92929D;
    background-color: #FFFFFF;
    z-index: 2;
    transition: .2s;
    user-select: none;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
.alertBlock{
    color: #FFA047;
    font-weight:600;
    margin-bottom: 10px;
}


/* WEEKDAY BLOCK */

.weekDaysEdit {
    display: flex;
    flex-flow: column nowrap;
    /* align-items: center; */
    justify-content: space-between;
    margin: 5px 0 10px;
    align-items: flex-start;
    /* gap: 8px; */
    border: 1px solid #FAFAFB;
    border-radius: 4px;
    background-color: #FAFAFB;
    padding: 15px 10px 0px;
}
.weekDaysContent {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}
.weekDaysEdit:hover{
    border: 1px solid #E2E2EA;
    
}
.weekDaysDelete, .weekDaysAdd {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #FAFAFB;
    background-color: #FAFAFB;
    flex-shrink: 0;
    cursor: pointer;
    transition: .2s;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.weekDaysAdd{
    background-color: #FFF;
    border: 1px solid #BFBFC6;
}
.weekDaysDeleteSVG{
    display: flex;
    transition: .2s;
}
.weekDaysDelete:hover, .weekDaysAdd:hover{
    border: 1px solid #E2E2EA;
    background-color: #E2E2EA;
    transition: .2s;
}
.weekDaysDelete:hover .weekDaysDeleteSVG{
    fill: #FFA047;
    transition: .2s;
}
.weekDaysDelete:active, .weekDaysAdd:active{
    background-color: #92929D;
}
.weekDaysAdd:hover .addSVG{
    fill: #139DD9;
    transition: .2s;
}
.buttonsBlock{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}