.wrap_listAuctions{
    margin:  0 auto 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4px;
    width: 1176px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Segoe UI';
    font-size: 14px;
    line-height: 19px;
}
.header_line{
    display: grid;
    grid-template-columns: 65px 303px 242px 108px 108px 250px 40px;
    column-gap: 4px;
    /* justify-items: center; */
    padding: 12px;
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
    font-weight: 600;
    color: #000;
    text-decoration: none;
}
.listAuctions{
    display: grid;
    grid-template-columns: 65px 303px 242px 108px 108px 250px 40px;
    column-gap: 4px;
    /* justify-items: center; */
    padding: 12px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
}
.listAuctions:hover{
    border: 1px solid #139DD9;
    transition: .2s;
}
.listAuctions_id{
    font-size: 14px;
    line-height: 19px;
    color: #696974;
}
.listAuctions_title{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #171725;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}
.listAuctions_price{
    font-size: 14px;
    line-height: 19px;
    color: #696974;
}
/* STATUSES */
.status_grey{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #92929D;
    padding-right: 8px;
}
.status_brown{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #A68800;
    padding-right: 8px;
}
.status_green{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #00B191;
    padding-right: 8px;
}
.status_black{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #171725;
    padding-right: 8px;
}
.status_darkGrey{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #696974;
    padding-right: 8px;
}
/* BID STATUSES */
.draft{
    font-weight: 600;
    color: #696974;
}
.active{
    font-weight: 600;
    color: #00B191;
}
.canceled{
    font-weight: 600;
    color: #FFA047;
}
.moderation{
    font-weight: 600;
    color: #A68800;
}
/* - ---- - */
.alert{
    color: #FFD200;
    text-decoration: none ;
}
.listAuctions_help{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* text-decoration-line: underline; */
    color: #139DD9;
}
.listAuctions_help div{
    margin-right: 10px;
}
.tdBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
}
.svgLink{
    transform: rotate(180deg);
}
.tdBtn:hover{
    border: 1px solid #139DD9;
    box-sizing: border-box;
    background-color: #139DD9;
    color: #FFFFFF;
    transition: .2s;
}
.tdBtn:hover .svgLinkPath{
    fill:#fff;
    transition: .2s;
}
a.link_title, a.link_title:visited{
    color: inherit;
    text-decoration: none;
}
.vFlex{
    display: flex;
    flex-flow: column nowrap;
}
