@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* info BLOCK */
.infoBlock {
    font-family: 'Segoe UI';
    width: 732px;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.infoBlock div:last-child{
    margin-bottom:0;
}
.orgTitle,
.orgAdress{
    width:100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.title_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px;
    font-size: 18px;
    font-weight: 600;
}
.title_button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 32px;
    padding: 2px 16px;
    background: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #FAFAFB;
    transition: .2s;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none; 
}
.title_button:hover, .active{
    background: #FFF;
    border: 1px solid #139DD9;
    color: #139DD9;
    transition: .2s;
}
.dropContent{
    display: flex;
    flex-flow: column;
    width: 100%;
}
.lineWrapAwards_text{
    text-decoration: line-through;
}