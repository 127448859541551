.messageBlock{
    font-family: 'Segoe UI';
    width: 100%;
    height: auto;
    border: 1px solid #FFD200;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.messageBlock{
    display: flex;
    font-size: 14px;
    justify-content: center;

}

.messageBlock a{
    color:#139DD9;
    text-decoration: underline !important;
    cursor: pointer;
}