.aucNav {
    position: relative;
    height: 50px;
    margin-top: 32px;
    display:flex;
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    border-bottom: 1px solid #E2E2EA;

}
.aucNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    border: 1px solid #E2E2EA;
    border-radius: 8px  8px 0 0;
    box-sizing: border-box;
    padding: 10px 20px 39px;
    position: relative;
    height: 40px;
    margin: 0px 5px;
}
.activeNavItem {
    padding: 10px 20px 39px;
    border-bottom: 1px solid #ffffff;
    color: #139DD9;
    font-weight: 600;
    -webkit-box-shadow: 3px -1px 6px 1px rgb(233 233 233);
    -moz-box-shadow: 3px -1px 6px 1px rgb(233 233 233);
    box-shadow: 3px -1px 6px 1px rgb(233 233 233);

}
.activeNavItem.aucNavItem::before{
    position: absolute;
    height: 10px;
    width: 100%;
    top: 49px;
    left: 0px;
    /* background-color: #139DD9; */
    background-color: #ffffff;
    content: ' ';
    /* border-radius: 10px 10px 0 0; */
}