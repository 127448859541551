
/* SELECT */
.inPutInfo{
    width: 100%;
    display: flex;
    position:relative;
    margin: 0 0 15px;
}
.selectWrap{
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}
.inPutWrap{
    width: 100%;
    display: flex;
    position:relative;
}
.disabledSelect,
.inPutSelect {
    width: 100%;
    height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color:inherit;
    outline:none;
    font-size: 14px;
    color: #171725;
    padding: 10px 20px 10px 10px;
    position: relative;
    cursor: pointer;
    transition: .5s;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
}
.disabledSelect{
    color: #92929D;
    cursor: default;
}
.textValue{
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 22px;
    /* white-space: nowrap; */
}
.dafault{
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    text-align: left;
    color: #92929D;
}
.inPutSelect:after{
    position: absolute;
    border: 6px solid transparent; 
    border-top: 6px solid #92929D;
    top:18px;
    right: 8px;
    font-size: 16px;
    content: ' ';
    color: #92929D;
}
.focus.inPutSelect:after{
    top:12px;
    border: 6px solid transparent; 
    border-bottom: 6px solid #00AFF0;
}
.half{
    width:49%;
}
.center{
        align-items: center;
}
.focus,
.inPutSelect:hover,
.inPutSelect:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.label {
    position: absolute;
    left: 8px;
    top: -5px;
    font-size: 11px;
    line-height: 9px;
    padding: 0px 5px;
    color: #92929D;
    background-color: #FFFFFF;;
    z-index: 2;
}
.focus + .label,
.inPutSelect:hover + .label,
.inPutSelect:focus + .label {
    color:#00AFF0;
    transition: .2s;
}
p.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: 0 0 5px 10px;
    top: -12px;
    position: relative;
}
.inPutInfo.attent .inPutSelect{
    border: 1px solid #FFA047;
}
.inPutInfo.attent + p,
.inPutInfo.attent .label{
    color: #FFA047;
}
.complete{
    color: #171725;
}

/* SELECT POPDOWN  */

.wrapper{
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
    /* transition: .8s; */
}
.selectPopdown{
    position:absolute;
    top: 45px;
    left: 0px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: inherit;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 6px 4px 6px 0px;
    box-sizing: border-box;
    z-index: 9998;
    cursor:initial;
    /* transition: .8s; */
    /* overflow-y: auto; */
}
.listContent{
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;
    margin: 0;
}
.topPart{
    width: 100%;
    min-height: 40px ;
    height: auto;
    background: #00AFF0;
    opacity: 0.1;
    border-radius: 10px;
    margin: 0 0 10px;
    display: flex;
    box-sizing: border-box;
}
.bottomPart{
    position:relative;
    height: auto;
    border-top: 1px solid #E2E2EA;
    padding: 16px 0;
    display: flex;
    box-sizing: border-box;
}
.somePart{
    padding: 4px 20px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    cursor: pointer;
    box-sizing: border-box;
}
.active{
    background-color: #FAFAFB;
    font-weight: 500;
}
.bold{
    font-weight: 600;
}
.disabled{
    padding: 4px 20px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
    color: #BFBFC6;
}
.somePart:last-child {
    margin-bottom: 0;
}
.somePart:hover{
    background-color: #FAFAFB;
    font-weight: 500;
    /* transition: .2s; */
}
.somePart span{
    color: #92929D;
}

.fontSize{
    font-size: 12px !important;
}

.fontSize:hover{
    font-size: 12px !important;
}