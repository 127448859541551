@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* info BLOCK */
.infoBlock {
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.infoBlock div:last-child{
    margin-bottom:0;
}
.infoBlock p, .draftProposal p {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    color: #171725;
}
.infoBlock p.desc, .draftProposal  p.desc {
    font-family: 'Roboto';
    font-size: 11px;
    color: #696974;
    font-style: normal;
    margin: 0;
}