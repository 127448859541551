.aukpoisk_second_elem{
    width: calc(110px - 20px);
    padding: 10px;
    display: flex;
    justify-content: center;
    position: relative;
}

.aukpoisk_second_elem a{
    text-decoration: none;
}

.aukpoisk_second_elem svg, .aukpoisk_second_elem img{
    width: 60px;
    height: 60px;
    margin: auto;
    margin-bottom: 15px;
}

.aukpoisk_second_elem_text{
    font: 400 12px/16px "robotoregular",Arial,sans-serif;
    text-align: center;
    text-decoration: none !important;
    color: #585858;
}

.aukpoisk_second{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
}

/*.base{
    display: flex;
    justify-content: center;
}*/
.base{
    width: 60px;
    height: 60px;
    margin: auto;
    margin-bottom: 15px;
    background-image: url('./railway.png');
    background-size: 100%;
}


/*.base:hover svg{
    fill: #000;
}

.base:hover + .aukpoisk_second_elem_text{
    color: #000 !important;
}

svg{
    fill: #585858;
}

.active svg{
    fill: #000;
}

.active + .aukpoisk_second_elem_text{
    color: #000 !important;
}*/
.base:hover{
    background-image: url('./railway_g.png');
}

svg{
    fill: #585858;
}

.active svg{
    fill: #000;
}

.active + .aukpoisk_second_elem_text{
    color: #000 !important;
}

.base.active{
    background-image: url('./railway_g.png');
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.aukpoisk_second_elem_1{
    display: none;
}

.aukpoisk_second_elem:hover .aukpoisk_second_elem_1{
    display: initial;
    position: absolute;
    top: 120px;
    left: -10px;
    right: -10px;
    /*bottom: -120px;*/
    z-index: 900;
    font-size: 12px;
    background-color: #fff;
    color: #585858;
}

.subMenu{
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border-bottom: 1px solid #000;
}

.subMenu:hover{
    background-color: #c3c3c3;
}