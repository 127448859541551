.auctions__wrap{
    margin: auto;
    margin-top: 20px;
}

.tab__wrap{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.auctionsLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c5c5;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
}

.auctionsLineElem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.disabledButton{
    background-color: darkgray;
    color: #000;
    padding: 5px;
    margin: 3px;
}

.enabledButton{
    background-color: #00aff0;
    color: #fff;
    padding: 5px;
    margin: 3px;
    cursor: pointer;
}

/*
@media (min-width: 320px){
    .auctions__wrap{
        width: 300px;
    }
}

@media (min-width: 730px){
    .auctions__wrap{
        width: 710px;
    }
}

@media (min-width: 960px){
    .auctions__wrap{
        width: 940px;
    }
}

@media (min-width: 1280px){*/
    .auctions__wrap{
        /*width: 1260px;*/
        width: 1200px;
    }
/*}

@media (min-width: 1440px){
    .auctions__wrap{
        width: 1320px;
    }
}

@media (min-width: 1600px){
    .auctions__wrap{
        width: 1480px;
    }
}

@media (min-width: 1920px){
    .auctions__wrap{
        width: 1800px;
    }
}*/

.documentsId{
    font-size: 14px;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
    border-right: 1px solid #000;
}

.aucID{
    width: 80px;
}
.aucTitle{
    width: 200px;
}
.aucProzorroId{
    width: 100px;
    min-height: 20px;
}
.aucStatus{
    width: 100px;
}

.auctionsLine:nth-child(odd){
    background-color: #c5c5c5;
}

.auctionsLine:hover{
    background-color: #5d8aa8;
}

.warningData{
    color: red;
}

.document{
    width: 220px;
}

.paging_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 25px;
}
.paging{
    width: 24px;
    height: 24px;
}

.countPage{
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    border-radius: 3px;
    text-align: center;
}