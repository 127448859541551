.documentWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.flexBlock{
    width: 100%;
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around; 
}

.flexBlockElem{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auctionsLine{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c5c5;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
}

.documentsForm{
    width: 95%;
}

.auctionsLineElem{
    width: 100%;
    display: flex;
    justify-content: left;
    border-bottom: 1px solid #000;
    margin-bottom: 6px;
}

div .inputText, div .selectWidth{
    margin-bottom: 10px;

}

.inputText, .selectWidth{
    width: 100%;
}

.buttonExitDocument{
    width: 300px;
}

.editButton{
    margin-right: 25px;
}

.lineButtons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.headers{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
   
   
.input__file_button_text {
    line-height: 1;
    margin-top: 1px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 20px;
}

.button{
    appearance: none;
    max-width: 280px;
    height: 60px;
    border: 0;
    background: #4676D7;
    color: #fff;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
}

.formLine{
    margin-top: 20px;
    margin-bottom: 30px;
}

.input__wrapper{
    height: 60px;
}

.documentsId{
    margin-right: 15px;
}

.disabledButton{
    background-color: darkgray;
    color: #000;
    padding: 5px;
    margin: 3px;
}

.enabledButton{
    background-color: #00aff0;
    color: #fff;
    padding: 5px;
    margin: 3px;
    cursor: pointer;
}

.elementOff{
    background-color: #ccc;
    color: #000;
}

/*8888888888888888*/
.h3Title{
    padding-left: 10px;
}

.lineWrapAwards{
    width: 100%;
    display: flex;
    color: #000 !important;
    font-size: 12px !important;
}

.lineWrapAwards_text{
    padding: 10px;
}

.lineWrapAwards_title{
    width: 240px;
    font-weight: 600;
}

.lineWrapAwards_titleContract{
    width: 350px;
    font-weight: 600;
}

.lineWrapAwards_content{
    width: 350px;
    overflow: hidden;
}

.lineWrapAwards_contentDate{
    width: 150px;
    overflow: hidden;
}

.buttonsContract{
    margin-bottom: 10px !important;
    width: 250px;
}

.lineBids_userDocumentHis{
    text-decoration: line-through;
}

.notCanter{
    text-align: left !important;
}

.blockButton{
    display: flex;
    justify-content: left;
    margin-bottom: 10px;
}