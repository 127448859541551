.lotWrap{
    width: 100%;
    background: #fdfefd;
}

.lotPages{
    width: 100%;
}

.lotContent{
    margin: auto;
}

/*@media (min-width: 320px){
    .lotContent{
        width: 300px;
    }
}

@media (min-width: 730px){
    .lotContent{
        width: 710px;
    }
}

@media (min-width: 960px){
    .lotContent{
        width: 940px;
    }
}

@media (min-width: 1280px){*/
    .lotContent{
        /*width: 1260px;*/
        width: 1200px;
    }
/*}

@media (min-width: 1440px){
    .lotContent{
        width: 1320px;
    }
}

@media (min-width: 1600px){
    .lotContent{
        width: 1480px;
    }
}

@media (min-width: 1920px){
    .lotContent{
        width: 1800px;
    }
}*/

.errorWrap{
    width: 1200px;
    height: auto;
    text-align: center;
    padding: 80px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.errorTitle{
    font-weight: 700;
    font-size: 100px;
    text-align: center;
    color:#171725;
}
.errorDespript{
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #44444F;
    margin: 14px 0;
}
.subDescript{
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #696974;
    margin: 40px 0;
}
.errorImage{
    margin: 40px 0 20px;
}