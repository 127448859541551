.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    padding: 40px;
    background-color: #fff;
    text-align: center;
    position: relative;
    z-index: 9999;
}

.popup_message_button{
    color: #fff;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    border: 0px solid #000;
    width: 100%;
}

.pseudoCheckboxOn{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    margin-top: 3px;
    margin-left: 10px;
    cursor: pointer;
}
.pseudoCheckboxOff{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    background-color: black;
    margin-top: 3px;
    margin-left: 10px;
    cursor: pointer;
}

.popup_closeX{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
    background-color: #eee;
}

.title{
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 20px;
}

.groupElem{
    margin-bottom: 10px;
}

.chekbox{
    display: flex;
    justify-content: left;
    margin-bottom: 15px;
}

.inputText{
    width: 100%;
}

.selectWidth{
    width: 100%;
    font-size: 14px;
    border-radius: 3px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-color: silver;
}

.input__wrapper{
    margin-top: 30px;
    position: relative;
    height: 45px;
}

.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
   
   
.input__file_button_text {
    line-height: 1;
    margin-top: 1px;
    border: 1px solid #000;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
}