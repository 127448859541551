.semiTitle{
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0;
}

.lineButtons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.headers{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.twoInLine{
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
}


@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.blokInputs{
    width: 100%;
    display: flex;
    flex-flow: column;
    border: 1px solid #FFD200;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
    padding: 10px 10px 0;
    margin: 0 0 18px 0;
}