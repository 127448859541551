.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    padding: 40px;
    background-color: #fff;
    text-align: center;
    position: absolute;
    top: 115px;
    bottom: 0;
    width: calc(100% - 80px);
}

.popup_closeX{
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    line-height: 30px;
    background-color: #eee;
}

.bankAccountElement{
    display: flex;
    font-size: 12px;
    border-bottom: 1px solid #000;
    margin-bottom: 5px;
}

.mainDocumentsBlock{
    background-color: #fff;
    text-align: center;
    position: absolute;
    top: 27%;
    bottom: 5%;
    overflow: auto;
}

.pn{
    width: 40px;
    text-align: center;
}

.bankTitle{
    width: 200px;
    text-align: left;
}

.bankDescription{
    width: 350px;
    text-align: left;
}

.accountIdentificationsDescription{
    width: 200px;
    text-align: left;
}

.accountIdentificationsIdentifier{
    width: 200px;
    text-align: left;
}

.accountIdentificationsShema{
    width: 200px;
    text-align: left;
}

.buttonExitDocument{
    margin-left: 10px !important;
}