.auction_main{
    width: 100%;
}

.auction_main_wrap{
    margin: auto;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    padding-top: 35px;
    padding-bottom: 15px;
    width: 1200px;
}

@media (min-width: 320px){
    
}
@media (min-width: 730px){
    
}
@media (min-width: 960px){
    
}
@media (min-width: 1280px){
    
}
@media (min-width: 1440px){
    
}
@media (min-width: 1600px){
    
}
@media (min-width: 1920px){
    
}