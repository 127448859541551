.wrapMain{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9998;
}
.wrap{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 518px;
    height: auto;
    background-color: #ffffff;
    z-index: 9999;
    margin: auto;
    font-size: 14px;
    padding: 24px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    transition: .8s;
}
.close{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    right:20px;
    top:20px;
    font-size: 18px;
    cursor:pointer;
    color:#92929D;
}
.close:hover{
    color:#139DD9;
    transition: .2s;
}
.popupTitle{
    font-size: 24px;
    text-align: left;
}
.description{
    margin: 8px 0 24px;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100% + 20px);
    margin: 10px 0;
    padding: 16px ;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}
.bottomPart{
    height: 56px;
    width: 100%;
    margin: 24px 0 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
}
.btnDone{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    margin: 0 0 0 20px;
    width: 111px;
    height: 40px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #139DD9;
    cursor: pointer;
}
.btnDone:hover {
    transition: .2s;
    background-color: #139DD9;
    color: #ffffff;
}
.btnCancel{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 111px;
    height: 40px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    color: #696974;
    cursor: pointer;
}
.btnCancel:hover {
    transition: .2s;
    background-color: #dfdfdf;
    /* color: #ffffff; */
}

/* DROP-ZONE */

.dropZone{
    width: 100%;
    height: 120px;
    margin: 18px 0 0;
    padding: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px dashed #CED6E0;
    box-sizing: border-box;
    transition: .8s;
}
.dropZoneActive{
    width: 100%;
    min-height: 120px;
    height: auto;
    margin: 18px 0 0;
    padding: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    border: 2.5px dashed #139DD9;
    box-sizing: border-box;
    transition: .8s;
}
input[type="file"]{
    display: none;
}
.btnPin{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 111px;
    height: 32px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #139DD9;
    cursor: pointer;
}
.btnPin:hover{
    border: 1px solid #139DD9;
    background-color: #139DD9;
    color: #fff;
    transition: .2s;
}

/* INPUT */ 
.bidLotCost_descr{
    color: #92929D;
    font-size: 14px;
    margin: 0 0 10px;
}
.bidLotCost_title{
    position: absolute;
    color: #92929D;
    top: -9px;
    left: 10px;
    background-color: #fff;
    padding: 0 5px;
}
.bidLotCost_input{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-flow: column nowrap;
    color: #6d6d6d;
    font-size: 12px;
    position: relative;
    margin: 10px 0 0;
}
.bidLotCost_input input[type="text"]{
    /* display: flex;
    align-items: center; */
    padding: 9px 12px 8px;
    width: 100%;
    height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
}
.bidLotCost_input input[type="text"]::placeholder,
.selectTypeFile::placeholder {
  color: #92929D;
  font-family: Segoe UI;
  font-size: 14px;
}
.bidLotCost_input input:hover,
.bidLotCost_input input:focus,
.selectTypeFile:hover,
.selectTypeFile:focus {
    border: 1px solid #139DD9;
    transition: .2s;
    outline: none;
}
.bidLotCost_input input:hover + .bidLotCost_title,
.bidLotCost_input input:focus + .bidLotCost_title{
    color:#139DD9;
    transition: .2s;
}
.selectWrap{
    position: relative;
}
.selectWrap:after{
    position: absolute;
    border: 6px solid transparent; 
    border-top: 6px solid #92929D;
    top:18px;
    right: 8px;
    font-size: 16px;
    content: ' ';
    color: #92929D;
}
.selectTypeFile:focus > .selectWrap:after{
    top:12px;
    border: 6px solid transparent; 
    border-bottom: 6px solid #00AFF0;
}
.selectTypeFile{
    padding: 9px 12px 8px;
    width: 100%;
    height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    margin: 0 0 12px 0;
    /* position: relative; */
}
.selectTypeFile{
	/* for Firefox */
	-moz-appearance: none;
	/* for Chrome */
	-webkit-appearance: none;
}
.selectTypeFile::-ms-expand {
	/* IE */
	display: none;
}
.selectTypeFile option[disabled]{
    color: #92929D;
    font-family: Segoe UI;
    font-size: 14px;
}
/* CHECKBOX */
.chekbox{
    /* padding-left: 15px; */
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: auto;
    margin: 20px 24px;
    box-sizing: border-box;
}
.pseudoCheckboxOff{
    width: 16px;
    height: 16px;
    border: 1px solid #92929D;
    border-radius: 4px;
    cursor: pointer;
}
.pseudoCheckboxOn{
    width: 16px;
    height: 16px;
    border: 1px solid #0062FF;
    border-radius: 4px;
    cursor: pointer;
    background-color:#0062FF;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='5' viewBox='1 -2 9 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.70251 2.7801C1.30947 2.39211 0.676317 2.39621 0.28833 2.78925C-0.0996578 3.1823 -0.095558 3.81545 0.297487 4.20344L2.83844 6.7117C3.25647 7.12434 3.93843 7.08954 4.31227 6.63648L8.43799 1.63648C8.78949 1.21049 8.7291 0.580209 8.30311 0.228708C7.87713 -0.122792 7.24685 -0.0624085 6.89535 0.363579L3.46543 4.52033L1.70251 2.7801Z' fill='white'/%3e%3c/svg%3e ");
    background-size: cover;
}
.chekboxLabel{
    width: 80%;
    display: flex;
    flex-flow: column wrap;
    font-size: 14px;
    margin: 0 0 0 15px;
}
.chekboxLabel span{
    display: block;
    color:#92929D;
}


.inputTextArea::placeholder {
  color: #92929D;
  font-family: 'Segoe UI';
  font-size: 14px;
}
.inputTextArea:hover,
input.inputText:focus,.inputTextArea:focus {
    border: 1px solid #139DD9;
    transition: .2s;
    outline: none;
}

.inputTextArea{
    padding: 9px 12px 8px;
    width: 100%;
    height: 80px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    margin: 12px 0 0;
    resize: none;
    overflow-y: auto;
}