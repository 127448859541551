/* JUST INPUT */
.inPutWrap{
    /* width: 100%; */
    display: flex;
    flex-flow: column nowrap;
    position:relative;
    margin: 0 0 15px;
}


input.timePick{
    width: 120px;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    /* color: #92929D; */
    color: #171725;
    padding: 0px 10px 0 10px;
    margin: 0;
    box-sizing: border-box;
}
input.timePick::placeholder{
    color:#E2E2EA;
}
input.timePick:hover,
input.timePick:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.disabled{
        width: 100%;
        height: 40px;
        font-size: inherit;
        background-color: inherit;
        outline: none;
        border: 1px solid #CED6E0;
        border-radius: 4px;
        position: relative;
        color: #92929D;
        padding: 0px 10px 0 10px;
        box-sizing: border-box;
}
.complete{
    color: #171725;
}

input.alert{
        /* width: 100%; */
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border-radius: 4px;
    position: relative;
    color: #171725;
    padding: 0px 10px 0 10px;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #FFA047;
}

label.label{
    position: absolute;
    max-width:75%;
    left: 18px;
    top: -6px;
    font-size:11px;
    line-height: 9px;
    padding: 1px 5px;
    color: #92929D;
    background-color: #FFFFFF;
    z-index: 2;
    transition: .2s;
    user-select: none;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
.timePick:hover + label.label,
.timePick:focus + label.label {
    color:#00AFF0;
    transition: .2s;
}
input.alert + label.label{
    color:#FFA047;
    transition: .2s;
}