.mainWrap{
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row nowrap;
    margin: 20px 0 20px;
    box-sizing: border-box;
}
.infoBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 20px 0 20px; 
}
.infoBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}
.editBtn{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    border-radius: 4px;
    color: #696974;
    border: 1px solid #E2E2EA;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    box-sizing: border-box;
    transition: .2s;
}
.editBtn:hover{
    background: #139DD9;
    border: 1px solid #139DD9;
    color: #FFF;
    transition: .2s;
}
.documentsBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 24px; */
    background: #FFFFFF;
    /* border: 1px solid #E2E2EA; */
    border-radius: 4px;
    flex: none;
    order: 0;
    box-sizing: border-box;
    margin: 20px 0 0;
}
.wrapContent{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}
.addDocBtn{
    width: auto;
    height: 38px;
    display: flex;
    border: 1px solid #139DD9;
    color: #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s;
    padding: 10px 16px;
    margin: 15px 0 0;
    font-weight: 600;
}
.addDocBtn:hover{
    border: 1px solid #139DD9;
    background-color: #139DD9;
    color: #fff;
    transition: .2s;
}
.interest{
    color: #696974;
    font-style: italic;
}