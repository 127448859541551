@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.sidelotImage{
    width: 100%;
}

.sidelotImage img{
    width: 150px;
    height: 150px;
    border-radius: 4px;
    cursor: pointer;
    object-fit:scale-down;
}

/*  IMAGE POPUP  */
.imageWrapper{
    display: flex;
    position: relative;
    margin: 0 15px;
    width:auto;
    height: auto;
    /* max-width: 1000px; */
    max-height: 800px;
    padding:8px;
    z-index: 999;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;     
}

.image{
    position: relative;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
    max-height: 800px;
}

.image img{
    max-height: 800px;
}

@media (max-height: 768px){
    .image{
        position: relative;
        display: flex;
        z-index: 999;
        align-items: center;
        justify-content: center;
        max-height: 440px;
    }
    .image img{
        max-height: 440px;
    }
}

.imageWrapperBg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background:rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}


.noDisplay{
    display: none;
}

.popUpClose{
    position: absolute;
    top: -38px;
    right: -60px;
    font-size: 64px;
    cursor: pointer;
    color:#ffffff;
    opacity: .3;
}

.popUpClose:hover{
    opacity: 1;
    transition: .2s;
}


.imageArrow{
    height: 300px;
    width:40px;
    display: flex;
    font-size: 62px;
    cursor: pointer;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    opacity: .3;
}

.imageArrowPlaceholder{
    height: 300px;
    width:40px;
    display: flex;
    font-size: 62px;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    opacity: .3;
}

.imageArrow:hover{
    opacity: 1;
    transition: .2s;
}