.langUA{
    margin-top: 9px;
    width: 26px;
    height: 26px;
    background-image: url('./lang.png');
    background-position-y: 0px;
}

.langEN{
    margin-top: 9px;
    width: 26px;
    height: 26px;
    background-image: url('./lang.png');
    background-position-y: 26px;
}

.login{
    display: inline-block;
    padding: 6px 10px;
    background: #00aff0;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 10px;
}

.wrapLang{
    margin-top: 15px;
    padding-left: 15px;
    /*border-left: 1px solid rgb(109, 109, 109);*/
    /*border-left: 1px solid #d9d9d9;*/
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    width: 100px;
    position: relative;
}

.wrapLang div:first-child::after{
    content: ' ';
    position: absolute;
    left: -5px;
    width: 1px;
    top: 0;
    /*bottom: 17px;*/
    height: 45px;
    background: #cacdd1;
    z-index: 10;
}


.wrapLang div:last-child{
    display: none;
}

.wrapLang:hover div:last-child{
    display: block;
}

.korusne_img{
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.korusne_title{
    font: 400 12px/14px "robotobold",Arial,sans-serif;
    color: #414141;
    text-align: left;
    text-transform: uppercase;
    margin-left: 5px;
    font-weight: 600;
}

.korusne_text{
    font: 400 11px/14px "robotolight",Arial,sans-serif;
    color: #666;
    text-align: left;
    text-transform: none;
    padding-left: 10px;
}

a.signin:link, a.signin:visited, a.signin:hover, a.signin:active{
    text-decoration: none;
}

.wrapLogin div{
    font: 400 12px/14px "robotolight",Arial,sans-serif;
    color: #666;
    text-transform: lowercase;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}