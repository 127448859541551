.blueBtn{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    font-size: 14px;
    font-weight: 600;
    font-family: inherit;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    position: static;
    width: 98px;
    height: 40px;
    background: #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 18px;
    color: #ffffff;
    cursor: pointer;
}