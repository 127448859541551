/* JUST inPutTextArea */
.inputWrap{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    position:relative;
    margin: 0 0 15px;
}
.inPutInfo{
    width: 100%;
    display: flex;
    position:relative;
    /* margin: 0 0 15px; */
}

.inPutWrap{
    width: 100%;
    display: flex;
    position:relative;
}
textarea.inPutTextArea{
    width: 100%;
    min-height: 75px;
    font-size: 14px;
    font-family: inherit;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #171725;
    padding: 8px 10px 0 10px;
    box-sizing: border-box;
    resize: vertical;
}
.focus,
textarea.inPutTextArea:hover,
textarea.inPutTextArea:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.half{
    width:49%;
}
.center{
        align-items: center;
}

.select{
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.59 6.37'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%2392929d;fillRule:evenodd;%7d%3c/style%3e%3c/defs%3e%3cg id='Слой_2' data-name='Слой 2'%3e%3cg id='Слой_1-2' data-name='Слой 1'%3e%3cpath class='cls-1' d='M9.09,0a.5.5,0,0,1,.39.81L5.18,6.18a.5.5,0,0,1-.78,0L.11.81A.5.5,0,0,1,.5,0Z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .7em top 55%, 0 0;
    background-size: .65em auto, 100%;
    cursor: pointer;
}
.label{
    position: absolute;
    left: 8px;
    top: -5px;
    font-size: 11px;
    line-height: 9px;
    padding: 0px 5px;
    color: #92929D;
    background-color: #FFFFFF;;
    z-index: 2;
}
.inPutTextArea:hover + .label,
.inPutTextArea:focus + .label {
    color:#00AFF0;
    transition: .2s;
}
.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin-left: 10px;
    top: 0px;
    position: relative;
}
.inPutInfo.attent .inPutTextArea
{
    border: 1px solid #FFA047;
}
.inPutInfo.attent+p,
.inPutInfo.attent>.label{
    color: #FFA047;
}
.disabled{
    width: 100%;
    height: 55px;
    font-size: 14px;
    font-family: inherit;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    padding: 8px 10px 0 10px;
    box-sizing: border-box;
    resize: vertical;
    color: #92929D;
}