.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    padding: 40px 40px 25px;
    display: flex;
    background-color: #fff;
    font-weight: 600;
    border-radius: 8px;
    position: relative;
    z-index: 9999;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}
.popup_btnBlock{
    display: flex;
    justify-content: space-around;
    flex-flow: row nowrap;
    width: 80%;
    margin: 25px 0 0;
}
.buttonClose{
    display: flex;
    width: 80px;
    border: 1px solid rgb(0, 175, 240);
    box-sizing: border-box;
    border-radius: 4px;
    margin: 25px 0 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px 16px;
    color:rgb(0, 175, 240);
    transition: .2s;
}
.buttonClose:hover{
    border: 1px solid rgb(0, 175, 240);
    background-color: rgb(0, 175, 240);
    color: #FFF;
    transition: .2s;
}
.buttonNo{
    display: flex;
    width: 80px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 25px 0 0;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px 16px;
    color: #696974;
    transition: .2s;
}
.buttonNo:hover{
    border: 1px solid #E2E2EA;
    background-color: #E2E2EA;
    color: #696974;
    transition: .2s;
}

.popup_message_button{
    color: #fff;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    border: 0px solid #000;
    width: 100%;
}

.editDoc{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
}

.editDoc_wrap{
    padding: 40px;
    background-color: #fff;
    text-align: center;
    overflow: auto;
}

.chekbox{
    width: 100px;
    display: flex;
}
.pseudoCheckboxOn{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
}
.pseudoCheckboxOff{
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    background-color: black;
}

.buttonOff{
    background-color: #ccc;
}

.sizeHashe{
    font-size: 9px;
}