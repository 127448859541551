.mainWrap{
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row nowrap;
    margin: 20px 0 20px;
    box-sizing: border-box;
}
.wrapBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 0 10px;
}
.wrapBlock:last-child{
    margin: 0;
}
.wrapBlockTitle,
.costBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}
.wrapContent{
    display: flex;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}
.costBlock{
    width: calc(100% - 40px);
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #00AFF0;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 0 10px;
}
.startDate{
    width: 100%;
    height: auto;
    display: flex;
    /*flex-direction: column;*/
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 32px;
    padding-bottom: 17px;
    background: #FFFFFF;
    border: 1px solid #00AFF0;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 0 10px;
}
.startDateDiv{
    width: 48%;
}
.startDateDiv1{
    display: flex;
}
.startDateDiv1 div{
    margin-right: 5px;
}
.startDateDiv1 div:last-child{
    margin-right: 0px;
}
.startDateDivInput{
    width: 675px;
}
.startDateDivSelect{
    width: 275px;
    font-size: 12px !important;
}

.startDateDivSelect .inputSelect{
    font-size: 12px !important;
}

.bottomButtonBlock{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}
.twoInLine{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 20px;
    /* align-items: center */
}

.orgBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 10px 0 10px;
}

.helpBlok{
    /*font-family: 'Segoe UI';*/
    font-weight: 600;
    color: red;
    width: 100%;
    height: auto;
    border: 1px solid #FFD200;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}