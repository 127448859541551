.info_wrap{
    height: calc(100vh - 247px);
}

@media (min-width: 320px){
    .info_wrap{
        width: 300px;
        margin: auto;
    }
}
@media (min-width: 730px){
    .info_wrap{
        width: 710px;
        margin: auto;
    }
}
@media (min-width: 960px){
    .info_wrap{
        width: 940px;
        margin: auto;
    }
}
@media (min-width: 1280px){
    .info_wrap{
        width: 1260px;
        margin: auto;
    }
}
@media (min-width: 1440px){
    .info_wrap{
        width: 1320px;
        margin: auto;
    }
}
@media (min-width: 1600px){
    .info_wrap{
        width: 1480px;
        margin: auto;
    }
}
@media (min-width: 1920px){
    .info_wrap{
        width: 1800px;
        margin: auto;
    }
}