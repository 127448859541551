.paging_wrap{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}
.countPage{
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    color: #139DD9;
}
.pageNavCounter {
    height: 32px;
    width: 61px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    order: 3;
    margin-left: 30px;
    align-items: right;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.counters{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 40%;
    border-left: 1px solid #E2E2EA;
}
.sumCount{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.sumArrowUp, .sumArrowDn{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.sumArrowUp:hover .svgIcon,
.sumArrowDn:hover .svgIcon{
    fill: #139DD9;
    transition: .2s;
}
.btnNext, .btnPrev{
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 1px solid #e2e2ea;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 15px;
    cursor: pointer;
}
.btnNext:hover, .btnPrev:hover {
     border: 1px solid #139DD9;
     transition: .2s;
}
.btnNext:hover .svgIcon,
.btnPrev:hover .svgIcon{
    fill: #139DD9;
    transition: .2s;
}