.popup_wrap{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9998;
    backdrop-filter: blur(1px) grayscale(60%);
}

.popup_message{
    display: flex;
    position: relative;
    flex-direction: column;
    width: auto;
    min-width: 320px;
    height: auto;
    background-color: #ffffff;
    z-index: 9999;
    margin: 35vh auto;
    font-size: 14px;
    padding: 24px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    transition: .8s;
    position: relative;
    gap: 30px;
}

.content{
    display: flex;
    justify-content: center;
    font-weight: 600;
    height: 100%;
    padding: 10px 0 20px;
}