@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.title_button{
    width: 80%;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 2px 16px;
    background: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #FAFAFB;
    transition: .2s;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none; 
}

.title_button:hover{
    background: #FFF;
    border: 1px solid #139DD9;
    color: #139DD9;
    transition: .2s;
}