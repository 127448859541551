.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, .4);
}
.popup_message{
    display: flex;
    flex-flow: column nowrap;
    padding: 24px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
    border-radius: 10px;
    width: 560px;
    position: relative;
}
.popUpExit{
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.popUpExit:hover .closeSvg{
    fill:#139DD9;
    transition: .2s;
}
.blokButton{
    margin: 30px 0 0;
    display: flex;
    justify-content: space-around;
}
.popupButtonYes{
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    width: 200px;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    box-sizing: border-box;
    border-radius: 4px;
    color: #139DD9;
    cursor: pointer;
}
.popupButtonYes:hover{
    background-color: #139DD9;
    color: #FFF;
    transition: .2s;
}
.popupButtonNo{
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    width: 200px;
    padding: 10px 16px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    color: #696974;
    cursor: pointer;
}
.popupButtonNo:hover{
    background-color: #E2E2EA;
    color: #696974;
    transition: .2s;
}
.popUpTitle{
    font-weight: 600;
    /* text-transform: uppercase; */
    font-size: 24px;
    margin: 20px 0 15px;
    text-align: center !important;
    color: #000 !important;
}
.inputWrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    color: #6d6d6d;
    flex-direction: column;
}
.documentName{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.documentName_input{
    width: 100%;
    width: 300px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
    border-radius: 4px;
    margin-top: 5px;
}
.documentDescription_input{
    width: 100%;
    /*width: 300px;*/
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 3px;
    border-radius: 4px;
    margin-top: 5px;
}
.input__file {
    opacity: 0;
    visibility: hidden;
    position: absolute;
}
.input__file_button_text {
    /* line-height: 1; */
    margin-top: 15px;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #139DD9;
    color: #139DD9;
    border-radius: 4px;
    padding: 16px;
    cursor: pointer;
}
.input__file_button_text:hover{
    border: 1px solid #139DD9;
    background-color: #139DD9;
    transition: .2s;
    color: #FFF;
}
.formLine{
    margin-top: 30px;
    margin-bottom: 20px;
}