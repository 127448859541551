.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
}

.popup_message{
    width: 600px;
    padding: 40px;
    background-color: #fff;
    text-align: center;
}

.popup_message_button{
    color: #fff;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    padding: 10px;
    text-transform: uppercase;
    cursor: pointer;
    border: 0px solid #000;
    width: 100%;
}

.answerWrap{
    border: 1px solid #c5c5c5;
    padding: 15px;
    margin: 10px;
}

.answerWrap_line{
    font-size: 18;
}

.answerWrap_line span{
    color: dimgray;
}


.terminu{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
}

.documentElem{
    border-left: 15px solid grey;
    padding-left: 10px;
}

.documentElem:hover{
    border-left-color: rgb(0, 175, 240);;
}

.bidLotCost{
    display: flex;
    justify-content: left;
    text-align: left;
    margin-top: 20px;
}

.bidLotCost_title{
    margin-left: 30px;
    margin-right: 60px;
    color: #6d6d6d;
}

.bidLotCost_input{
    color: #6d6d6d;
    font-size: 12px;
}

.bidLotCost_input input{
    width: 400px;
    border: 1px solid #5d5d5d;
    font-size: 14px;
    padding: 3px;
}

.inputNumber::-webkit-outer-spin-button,
.inputNumber::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.selectType{
    margin-left: -10px;
    width: 408px;
    padding: 3px;
}

.inputTextArea{
    width: 400px;
    height: 150px;
}

.answerButton{
    width: 407px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 205px;
    background-color: rgb(0, 175, 240);
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}

.newQuestionBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    padding: 24px;    
    position: static;
    width: calc(100% - 50px);
    height: auto;
    background: #ffffff;
    border: 1px solid #00AFF0;
    border-radius: 4px;
    margin: 0 auto;
}
.title{
    margin: 10px 0 25px;
    color:#171725;
}
.inputStyle{
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #92929D;
    padding: 0px 10px 0 10px;
    box-sizing: border-box;
}
.inputStyle:hover,
.inputStyle:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.textArea{
    width: 100%;
    height: 130px;
    font-size: 14px;
    font-family: inherit;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #92929D;
    padding: 8px 10px 0 10px;
    box-sizing: border-box;
    resize: none;
    margin-top: 30px;
}
.textArea:hover,
.textArea:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
::-webkit-input-placeholder {
    color:#92929D;
    font-size: 14px;}
::-moz-placeholder          {
    color:#92929D;
    font-size: 14px;}/* Firefox 19+ */
:-moz-placeholder           {
    color:#92929D;
    font-size: 14px;}/* Firefox 18- */
:-ms-input-placeholder      {
    color:#92929D;
    font-size: 14px;}
.blueBtn{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    font-size: 14px;
    font-weight: 600;
    font-family: inherit;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    position: static;
    width: 98px;
    height: 40px;
    background: #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 18px;
    color: #ffffff;
    cursor: pointer;
}

.btnBlock{
    display: flex;
    justify-content: flex-end;
}

.btnBlock .blueBtn:first-child{
    margin-right: 20px;
}