.awardBlock {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.awardBlock_title{
    display: flex;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    height: auto;
}
.slimmer{
    position: absolute;
    right: 24px;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #139DD9;
    cursor: pointer;
}
.awardBlockContent{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 30px 0 0;
}
.blockHeader{
    font-size: 14px;
    font-weight: 600;
    color:#696974;
    margin-bottom: -5px;
}

/* BUTTONS */
.btnBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0 0;
}
.btnAccept{
   display: flex;
   font-weight: 600;
   font-size: 14px;
   line-height: 14px;
   justify-content: center;
   align-items: center;
   padding: 10.5px 16px;
   width: auto;
   height: 40px;
   border: 1px solid #139DD9;
   background-color: #139DD9;
   border-radius: 4px;
   box-sizing: border-box;
   color: #FFF;
   cursor: pointer;
   margin: 0 0 0 12px;
   text-align: center;
}
.btnAccept:hover{
   border: 1px solid #139DD9;
   background-color: #FFF;
   color: #139DD9;
   transition: .2s;
}
.btnDecline{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: auto;
    height: 40px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    color: #696974;
    cursor: pointer;
    margin: 0 0 0 12px;
}
.btnDecline:hover{
    border: 1px solid #E2E2EA;
    background-color: #E2E2EA;
    color: #696974;
    transition: .2s;
}
.btnDisabled{
    height: 40px;
    display: flex;
    border:1px solid #E2E2EA;
    background-color: #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 24px 0 0 10px;
    color: #92929d;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 16px;
    cursor: default;
    transition: .2s;
}
.btnDisabled:hover{
    border: 1px solid #E2E2EA;
    background-color: #E2E2EA;
    color: #696974;
    transition: .2s;
    cursor: not-allowed;
}
.btnToList{
   display: flex;
   font-weight: 600;
   font-size: 14px;
   justify-content: center;
   align-items: center;
   padding: 10.5px 16px;
   width: auto;
   height: 40px;
   border: 1px solid #E2E2EA;
   border-radius: 4px;
   box-sizing: border-box;
   color: #E2E2EA;
   cursor: default;
   margin: 0 0 0 12px;
}
.titleTextButtons{
    font-size: 14px;
    font-weight: 600;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 0 10px;
}

.buttonsBlokFlex{
    display: flex;
    justify-content: center;
}
.buttonsBlockYesNo{
    position: relative;
    width: 42%;
    height: auto;
    border: 1px solid #E2E2EA;
    display: flex wrap;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 0 0 auto;
    padding: 8px  8px 12px;
}