.posts_wrap_title{
    width: 100%;
    background-color: #646c7a;
    color: #e3e3e3;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 15px;
}

.posts_wrap{
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.postsContent{
    margin: auto;
}

.posts_title{
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
}

.posts_text{}

@media (min-width: 320px){
    .postsContent{
        width: 300px;
    }
}

@media (min-width: 730px){
    .postsContent{
        width: 710px;
    }
}

@media (min-width: 960px){
    .postsContent{
        width: 940px;
    }
}

@media (min-width: 1280px){
    .postsContent{
        width: 1260px;
    }
}

@media (min-width: 1440px){
    .postsContent{
        width: 1320px;
    }
}

@media (min-width: 1600px){
    .postsContent{
        width: 1480px;
    }
}

@media (min-width: 1920px){
    .postsContent{
        width: 1800px;
    }
}