/* NEW STYLES */

.infoBlock {
    font-family: 'Segoe UI';
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
.infoBlock div:last-child{
    margin-bottom:0;
}
.infoBlock p{
    margin: 0;
    font-size: 14px;
    font-style: normal;
    color: #171725;
}
.infoBlock h2,.infoBlock h3{
    width:50%;
    margin: 0;
}
.buttonLink{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    color:#FFF;
}
.documentElem{
    display: flex;
    flex-flow: column nowrap;
}
.buttonLinkBottom{
    margin: 10px 0 0;
}
.buttonLink:hover {
    background: #FFF;
    border: 1px solid #139DD9;
    transition: .2s;
    color: #139DD9;
}
.colorLink {
    font-weight: 600;
}

.infoBlock h3 {
    width: 100%;
}