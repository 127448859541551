.contractBlock {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.contractBlock_title{
    display: flex;
    font-size: 20px;
    font-weight: 600;
    width: 80%;
    height: auto;
}
.slimmer{
    position: absolute;
    right: 24px;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #139DD9;
    cursor: pointer;
}
.contractBlockContent{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 30px 0 0;
}

/* BUTTONS */
.btnBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 0 0;
}
.btnAccept, .btnPaid{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: auto;
    height: 50px;
    border: 1px solid #139DD9;
    background-color: #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #FFF;
    cursor: pointer;
    margin: 0 0 0 12px;
    text-align: center;
}
.btnAccept:hover, .btnPaid:hover{
    border: 1px solid #139DD9;
    background-color: #FFF;
    color: #139DD9;
    transition: .2s;
}
.btnDecline{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: auto;
    height: 50px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    color: #696974;
    cursor: pointer;
    margin: 0 0 0 12px;
    text-align: center;
}
.btnDecline:hover{
    border: 1px solid #E2E2EA;
    background-color: #E2E2EA;
    color: #696974;
    transition: .2s;
}
.btnToList, .btnPaidInact{
   display: flex;
   font-weight: 600;
   font-size: 12px;
   justify-content: center;
   align-items: center;
   padding: 10.5px 16px;
   width: auto;
   height: 50px;
   border: 1px solid #E2E2EA;
   border-radius: 4px;
   box-sizing: border-box;
   color: #E2E2EA;
   cursor: default;
   margin: 0 0 0 12px;
   text-align: center;
}
.btnPaid, .btnPaidInact{
    width: 327px;
}
.titleTextButtons{
    font-size: 14px;
    font-weight: 600;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 0 10px;
}

.buttonsBlokFlex{
    display: flex;
    justify-content: center;
}
.buttonsBlockYesNo{
    position: relative;
    width: 42%;
    height: auto;
    border: 1px solid #E2E2EA;
    display: flex wrap;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 10px;
    padding: 8px 12px;
}
.btnBlockDate{
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    padding: 0 0 0 15px;
    margin: 15px 0 0 72px;
}
.contractFirst{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*height: 410px;*/
    background: #fff;
    padding: 24px;
    z-index: 99;
}
.viewPreliminaryContract{
    width: 100%;
    margin-top: 25px;
}
.contractData{
    font-size: 11px;
    line-height: 9px;
    color: #92929D;
    background-color: #FFFFFF;
    margin-bottom: 5px;
}