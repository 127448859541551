.profile_wrap{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.profileContent{
    margin: auto;
}

.profile_elem{
    width: 100%;
    margin-bottom: 30px;
    border-left: 5px solid grey;
    padding-left: 10px;
}

.profile_elem:hover{
    border-left-color: rgb(0, 175, 240);;
}

.profileTitle{
    color: #585858;
    font: 400 10px/10px 'robotolight',Arial,sans-serif;
    text-transform: uppercase;
    padding: 0 0 17px;
}

.selectWidth{
    width: 400px;
}

div .selectWidth{
    margin-bottom: 20px;
}

@media (min-width: 320px){
    .profileContent{
        width: 300px;
    }
}

@media (min-width: 730px){
    .profileContent{
        width: 710px;
    }
}

@media (min-width: 960px){
    .profileContent{
        width: 940px;
    }
}

@media (min-width: 1280px){
    .profileContent{
        width: 1260px;
    }
}

@media (min-width: 1440px){
    .profileContent{
        width: 1320px;
    }
}

@media (min-width: 1600px){
    .profileContent{
        width: 1480px;
    }
}

@media (min-width: 1920px){
    .profileContent{
        width: 1800px;
    }
}