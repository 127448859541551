.wrap{
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.wrapNew{
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding: 10px 30px 10px 10px;
    /* border: 1px solid rgb(223, 223, 223); */
    box-sizing: border-box;
    background: #FAFAFB;
}

.mainBlock {
    width: 780px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.sideBlock {
    width: 370px;
    height: auto;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    /* background-color: forestgreen; */
    padding: 24px 0 0 24px;
}

.aucProperties{
    width: 780px;
    height: auto;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 24px;
    box-sizing: border-box;
    margin-bottom: 20px;
}