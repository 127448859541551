.wrapper{
    position: relative;
    width: 42%;
    height: auto;
    border: 1px solid #E2E2EA;
    display: flex wrap;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 0 0 auto;
    padding: 8px  8px 12px;
}
.wrapper__title{
    font-size: 14px;
    font-weight: 600;
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 0 10px;
}
.buttons{
    display: flex;
    justify-content: center;
}

.btn{
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: auto;
    height: 40px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 0 0 0 12px;
    text-align: center;
 }

 .accept{
    border: 1px solid #139DD9;
    background-color: #139DD9;
    color: #FFF;
 }
 .accept:hover{
    border: 1px solid #139DD9;
    background-color: #FFF;
    color: #139DD9;
    transition: .2s;
 }
 .decline{
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    color: #696974;
 }
.decline:hover{
     border: 1px solid #E2E2EA;
     background-color: #E2E2EA;
     color: #696974;
     transition: .2s;
 }