@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


/* LOT COMPOSITION */
.lotFull {
    width:100%;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.lotHalf {
    width:50%;
    margin-bottom: 10px;
    padding-right: 20px;
    box-sizing: border-box;
}

.lotFull p.desc, .lotHalf  p.desc {
    font-family: 'Roboto';
    font-size: 12px;
    color: #696974;
    font-style: normal;
    margin: 0;
}

.lotHalf p a, .lotFull p a{
    text-decoration: underline !important;
    font-weight: 600;
    color: #00aff0 !important;
}

.lotFullLink{
    margin-bottom: 20px;
}