.blockWrap {
    width: 100%;
    padding: 24px;
    background-color: #FFFFFF;
    margin-bottom: 25px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
}
.fullWidth {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0;
    gap: 60px;
}
.thirdWidth {
    width: 32%;
    margin: 0;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 19px;
    margin-bottom: 15px;
}