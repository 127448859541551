.flexBlock{
    margin-top: 40px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-around; 
}
.group{
    margin-bottom: 20px;
}

.groupElem{
    display: flex;
    justify-self: start;
    align-items: center;
    margin: 10px;
    /*width: 100%;*/
}

.fullWidth {
    width: 100%;
}

.groupElem div{
    margin-right: 10px;
}

.textAreaWidth, .inputText, .selectWidth{
    width: 800px;
}

.bottonBlock{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.flexBlockElem{
    width: 50%;
}

.auctionsLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #c5c5c5;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
}

.auctionsLineElem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonExitDocument{
    width: 300px;
}

.editButton{
    margin-right: 25px;
}

.lineButtons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.headers{
    width: 95%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemsId{
    margin-right: 15px;
}


.groupElemCategory select{
    width: 742px;
}
@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}
.mainWrap{
    width: 1200px;
    height: auto;
    display: flex;
    flex-direction: row;
    margin: 20px 0 20px;
    box-sizing: border-box;
}
.wrapBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 20px 0 20px;
}
.wrapBlock:last-child{
    margin: 0;
}
.wrapBlockTitle{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 20px;
}
.wrapContent{
    display: flex;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}
button.editButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 10px 16px;
    background: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    color: #fff;
    margin-left: 10px;
    cursor: pointer;
    transition: .2s;
}
button.editButton:hover{
    background: #fff;
    border: 1px solid #139DD9;
    color: #139DD9;
    transition: .2s;
}
button.cancel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 10px 16px;
    background: #fff;
    border: 1px solid #92929D;
    border-radius: 4px;
    color: #92929D;
    margin-left: 10px;
    cursor: pointer;
    transition: .2s;
}
button.cancel:hover{
    background: #92929D;
    border: 1px solid #92929D;
    color: #fff;
    transition: .2s;
}
.buttonBack{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0 15px;
}

.inPutWrap, .inPutWrapColumn {
    padding-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position:relative;
}