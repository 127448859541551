.header{
    width: 100%;
}

.contacts{
    display: flex;
    justify-content: space-between;
}

.contacts_iframe{
    width: 60%;
}