.slider_wrap{
    width: 100%;
    height: 545px;
    background: url('./slider.jpg') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -102px;
    position: relative;
}

.slider_wrap_black{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('./background.png');
}

.connect{
    font-family: "robotoregular",Arial,sans-serif;
    width: 1200px;
    margin: auto;
    margin-top: 252px;
    color: #fff;
    min-height: 300px;
}

.block_title{
    color: #fff;
    font-weight: 400;
    font-size: 30px;
    width: 65%;
    margin-bottom: 30px;
}

.block_description{
    color: #fff;
    font-size: 15px;
    width: 75%;
}

/*@media (min-width: 320px){
    .connect{
        width: 300px;
    }
}
@media (min-width: 730px){
    .connect{
        width: 710px;
    }
}
@media (min-width: 960px){
    .connect{
        width: 940px;
    }
}
@media (min-width: 1280px){*/
    .connect{
        /*width: 1260px;*/
        width: 1200px;
    }
/*}
@media (min-width: 1440px){
    .connect{
        width: 1320px;
    }
}
@media (min-width: 1600px){
    .connect{
        width: 1480px;
    }
}
@media (min-width: 1920px){
    .connect{
        width: 1800px;
    }
}*/

.block_hero{
    font-size: 22px;
}